import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTexty from '../../lib/Texty';

import Label from '../../atomic-design-components/atoms/Label/Label';
import Switch from '../../atomic-design-components/molecules/Switch/Switch';
import Tag from '../../atomic-design-components/atoms/Tag/Tag';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import Userpic from '../../atomic-design-components/atoms/Userpic/Userpic';
import { useGeneralWebsocket } from '../../containers/Chats/useChatsWebsockets';
import { toggleIsUserOnline } from '../../redux-saga/actions';
import { selectIsUserOnline, selectUserDetails } from '../../redux-saga/selectors';
import { StyledMenuItem } from './styled';

const UserBlockWithWebsocket = ({ isExtended, isMobile, t }: {
	isExtended: boolean;
	isMobile: boolean;
	t: (key: string) => string;
}) => {
	const dispatch = useDispatch();
	const isUserOnline = useSelector(selectIsUserOnline);
	const user = useSelector(selectUserDetails);

	useGeneralWebsocket(isUserOnline);

	const onToggleOnline = () => {
		dispatch(toggleIsUserOnline(!isUserOnline));
	};

	return (
		<StyledMenuItem className="userState">
			<Userpic
				onlineState={isUserOnline ? 'online' : 'offline'}
				height={36}
				width={36}
				src={user.member_profile_picture}
			/>

			<div className={clsx('userInfo', 'condensedItem', isExtended && 'extendedItem')}>
				<Typography as={isExtended && ReactTexty} variant="caption1" text={user.member_full_name} />
				<Label as={isExtended && ReactTexty} size="big" text={user.email} className="userEmail" />
				<Tag text={t(`all:${user.role}`)} />
			</div>

			<Switch
				className={clsx('condensedItem', isExtended && 'extendedItem')}
				checked={isUserOnline}
				onChange={onToggleOnline}
				handleDiameter={isMobile ? 26 : undefined}
				height={isMobile ? 32 : undefined}
				width={isMobile ? 50 : undefined}
				id="onlineStatus"
			/>
		</StyledMenuItem>
	);
};

export default UserBlockWithWebsocket;