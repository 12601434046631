// DATA
export const SET_DATA_TYPE = 'DATA/SET_TYPE';
export const GET_TABLE_DATA = 'DATA/GET_TABLE_DATA';
export const GET_TABLE_DATA_SUCCESS = 'DATA/GET_TABLE_DATA_SUCCESS';
export const GET_TABLE_DATA_ERROR = 'DATA/GET_TABLE_DATA_ERROR';
export const GET_OPEN_DATA = 'DATA/GET_OPEN_DATA';
export const SET_TABLE_DATA = 'DATA/SET_TABLE_DATA';
export const GET_OPEN_DATA_SUCCESS = 'DATA/GET_OPEN_DATA_SUCCESS';
export const KNOWLEDGE_BASE_UPDATE_POSITION = 'DATA/KNOWLEDGE_BASE_UPDATE_POSITION';

// SIDEBAR
export const GET_TABLE_ITEM_DETAILS = 'SIDEBAR/GET_TABLE_ITEM_DETAILS';
export const GET_TABLE_ITEM_DETAILS_ERROR = 'SIDEBAR/GET_TABLE_ITEM_DETAILS_ERROR';
export const GET_TABLE_ITEM_DETAILS_SUCCESS = 'SIDEBAR/GET_TABLE_ITEM_DETAILS_SUCCESS';
export const SIDEBAR_ITEM_SET = 'SIDEBAR/ITEM_SET';
export const SIDEBAR_ITEM_RESET = 'SIDEBAR/ITEM_RESET';
export const SIDEBAR_UPSERT = 'SIDEBAR/UPSERT';
export const SIDEBAR_UPSERT_ERROR = 'SIDEBAR/UPSERT_ERROR';
export const SIDEBAR_UPSERT_SUCCESS = 'SIDEBAR/UPSERT_SUCCESS';
export const GENERATE_INVOICE = 'SIDEBAR/GENERATE_INVOICE';
export const GENERATE_INVOICE_SUCCESS = 'SIDEBAR/GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_ERROR = 'SIDEBAR/GENERATE_INVOICE_ERROR';
export const INVITATIONS_SEND = 'SIDEBAR/INVITATIONS_SEND';
export const INVITATIONS_SEND_SUCCESS = 'SIDEBAR/INVITATIONS_SEND_SUCCESS';
export const INVITATIONS_SEND_ERROR = 'SIDEBAR/INVITATIONS_SEND_ERROR';
export const INVITATIONS_RESEND = 'SIDEBAR/INVITATIONS_RESEND';
export const INVITATIONS_RESEND_SUCCESS = 'SIDEBAR/INVITATIONS_RESEND_SUCCESS';
export const INVITATIONS_RESEND_ERROR = 'SIDEBAR/INVITATIONS_RESEND_ERROR';
export const GET_INVITATION_LINK = 'SIDEBAR/GET_INVITATION_LINK';
export const GET_INVITATION_LINK_SUCCESS = 'SIDEBAR/GET_INVITATION_LINK_SUCCESS';
export const GET_INVITATION_LINK_ERROR = 'SIDEBAR/GET_INVITATION_LINK_ERROR';
export const DELETE_ENTITY = 'SIDEBAR/DELETE_ENTITY';

// FILE UPLOAD
export const FILE_UPLOAD = 'FILE/UPLOAD';
export const FILE_UPLOAD_SUCCESS = 'FILE/UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE/UPLOAD_ERROR';
export const INPUT_FILES_ADD = 'FILES/INPUT_FILES_ADD';
export const INPUT_FILE_REMOVE = 'FILES/INPUT_FILE_REMOVE';
export const INPUT_FILES_CLEAR = 'FILES/INPUT_FILES_CLEAR';
export const FILE_URL = 'FILES/FILE_URL';
export const SET_FILE_URL_LOADED = 'FILES/SET_FILE_URL_LOADED';

// AUTH
export const GET_CURRENT_USER = 'AUTH/GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'AUTH/GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_ERROR = 'AUTH/GET_CURRENT_USER_ERROR';
export const GET_CURRENT_SUBSCRIPTION = 'AUTH/GET_CURRENT_SUBSCRIPTION';
export const GET_CURRENT_SUBSCRIPTION_SUCCESS = 'AUTH/GET_CURRENT_SUBSCRIPTION_SUCCESS';
export const GET_CURRENT_SUBSCRIPTION_ERROR = 'AUTH/GET_CURRENT_SUBSCRIPTION_ERROR';
export const SIGN_UP = 'AUTH/SIGN_UP';
export const SIGN_UP_SUCCESS = 'AUTH/SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'AUTH/SIGN_UP_ERROR';
export const SIGN_IN = 'AUTH/SIGN_IN';
export const SIGN_IN_SUCCESS = 'AUTH/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'AUTH/SIGN_IN_ERROR';
export const SIGN_OUT = 'AUTH/SIGN_OUT';
export const CONFIRM_EMAIL = 'AUTH/CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = 'AUTH/CONFIRM_EMAIL_SUCCESS';
export const TOKEN_REFRESH = 'AUTH/TOKEN_REFRESH';
export const TOKEN_REFRESH_ERROR = 'AUTH/TOKEN_REFRESH_ERROR';
export const REQUEST_RESET_PASSWORD = 'AUTH/REQUEST_RESET_PASSWORD';
export const RESET_PASSWORD = 'AUTH/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'AUTH/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'AUTH/RESET_PASSWORD_ERROR';
export const GET_INVITE_DETAILS = 'AUTH/GET_INVITE_DETAILS';
export const GET_INVITE_DETAILS_SUCCESS = 'AUTH/GET_INVITE_DETAILS_SUCCESS';
export const TOGGLE_IS_USER_ONLINE = 'AUTH/TOGGLE_IS_USER_ONLINE';
export const SET_USER_AVATAR = 'AUTH/SET_USER_AVATAR';
export const VERIFY_TOKEN = 'AUTH/VERIFY_TOKEN';

// COMPANY
export const GET_COMPANY_DATA = 'COMPANY/GET_COMPANY_DATA';
export const GET_COMPANY_DATA_SUCCESS = 'COMPANY/GET_COMPANY_DATA_SUCCESS';
export const GET_COMPANY_DATA_ERROR = 'COMPANY/GET_COMPANY_DATA_ERROR';
export const UPDATE_COMPANY_DATA = 'COMPANY/UPDATE_COMPANY_DATA';
export const UPDATE_COMPANY_DATA_SUCCESS = 'COMPANY/UPDATE_COMPANY_DATA_SUCCESS';
export const UPDATE_COMPANY_DATA_ERROR = 'COMPANY/UPDATE_COMPANY_DATA_ERROR';
export const GET_BILLING_PLANS = 'COMPANY/GET_BILLING_PLANS';
export const GET_BILLING_PLANS_SUCCESS = 'COMPANY/GET_BILLING_PLANS_SUCCESS';
export const GET_BILLING_PLANS_ERROR = 'COMPANY/GET_BILLING_PLANS_ERROR';
export const UPDATE_CURRENT_PLAN = 'COMPANY/UPDATE_CURRENT_PLAN';
export const UPDATE_CURRENT_PLAN_SUCCESS = 'COMPANY/UPDATE_CURRENT_PLAN_SUCCESS';
export const UPDATE_CURRENT_PLAN_ERROR = 'COMPANY/UPDATE_CURRENT_PLAN_ERROR';
export const CANCEL_SUBSCRIPTION = 'COMPANY/CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'COMPANY/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'COMPANY/CANCEL_SUBSCRIPTION_ERROR';
export const DELETE_COMPANY = 'COMPANY/DELETE_COMPANY';

// COMMON
export const POPUP_ALERT_SHOW = 'COMMON/POPUP_ALERT_SHOW';
export const POPUP_ALERT_HIDE = 'COMMON/POPUP_ALERT_HIDE';
export const POPUP_ALERT_HIDE_ALL = 'COMMON/POPUP_ALERT_HIDE_ALL';
export const INITIALIZE_APP = 'COMMON/INITIALIZE_APP';
export const TOGGLE_NAV_MENU_EXTENDED = 'COMMON/TOGGLE_NAV_MENU_EXTENDED';
export const TOGGLE_DRAWER_EXTENDED = 'COMMON/TOGGLE_DRAWER_EXTENDED';

// FORM
export const SET_FORM_VALUES = 'FORM/SET_FORM_VALUES';
export const RESET_FORM_VALUES = 'FORM/RESET_FORM_VALUES';

// CHATS
export const GET_CHATS_LIST = 'CHATS/GET_CHATS_LIST';
export const SET_CHAT_MESSAGE_ID_TO_LAST_MESSAGE = 'CHATS/SET_CHAT_MESSAGE_ID_TO_LAST_MESSAGE';
export const GET_CHATS_COUNT_NEW = 'CHATS/GET_CHATS_COUNT_NEW';
export const GET_CHATS_LIST_SUCCESS = 'CHATS/GET_CHATS_LIST_SUCCESS';
export const GET_ONLINE_CONTACTS_SUCCESS = 'CHATS/GET_ONLINE_CONTACTS_SUCCESS';
export const SET_CONTACT_NAME_TO_CHAT = 'CHATS/SET_CONTACT_NAME_TO_CHAT';
export const GET_CHAT_DETAILS = 'CHATS/GET_CHAT_DETAILS';
export const GET_CHAT_DETAILS_SUCCESS = 'CHATS/GET_CHAT_DETAILS_SUCCESS';
export const GET_CHAT_DETAILS_ERROR = 'CHATS/GET_CHAT_DETAILS_ERROR';
export const SET_EVENT_TO_CHAT_HISTORY = 'CHATS/SET_EVENT_TO_CHAT_HISTORY';
export const HIDE_CONTACT_TYPING = 'CHATS/HIDE_CONTACT_TYPING';
export const SET_EVENT_TO_CHAT_HISTORY_SUCCESS = 'CHATS/SET_EVENT_TO_CHAT_HISTORY_SUCCESS';
export const SET_EVENT_TO_CHAT_HISTORY_ERROR = 'CHATS/SET_EVENT_TO_CHAT_HISTORY_ERROR';
export const FIND_ALL_MESSAGES = 'CHATS/FIND_ALL_MESSAGES';
export const SEARCH_CHAT_MESSAGE = 'CHATS/SEARCH_CHAT_MESSAGE';
export const SEARCH_CHAT_MESSAGE_SUCCESS = 'CHATS/SEARCH_CHAT_MESSAGE_SUCCESS';
export const SEARCH_CHAT_MESSAGE_ERROR = 'CHATS/SEARCH_CHAT_MESSAGE_ERROR';
export const SET_FOUND_MESSAGES_IDS = 'CHATS/SET_FOUND_MESSAGES_IDS';
export const SET_FOUND_MESSAGES_IDS_ERROR = 'CHATS/SET_FOUND_MESSAGES_IDS_ERROR';
export const GET_CHAT_DETAILS_ON_SCROLL = 'CHATS/GET_CHAT_DETAILS_ON_SCROLL';
export const GET_CHAT_DETAILS_ON_SCROLL_SUCCESS = 'CHATS/GET_CHAT_DETAILS_ON_SCROLL_SUCCESS';
export const SET_NEW_CHAT_HISTORY_NOTIFICATION = 'CHATS/SET_NEW_CHAT_HISTORY_NOTIFICATION';
export const RESET_NEW_CHAT_HISTORY_NOTIFICATIONS = 'CHATS/RESET_NEW_CHAT_HISTORY_NOTIFICATIONS';
export const SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL = 'CHATS/SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL';
export const RESET_FOUND_MESSAGES = 'CHATS/RESET_FOUND_MESSAGES';
export const SET_CONTACT_ONLINE_STATUS = 'CHATS/SET_CONTACT_ONLINE_STATUS';
export const SET_CHAT_MESSAGE = 'CHATS/SET_CHAT_MESSAGE';
export const SET_IS_EVENT_JUST_SENT = 'CHATS/SET_IS_EVENT_JUST_SENT';
export const DELETE_CHAT = 'CHATS/DELETE_CHAT';
export const REPLIED_MESSAGE = 'CHATS/REPLIED_MESSAGE';