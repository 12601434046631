import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FILE_TYPES } from '../../constants';

import { getUploadedData, inputFilesAdd } from '../../redux-saga/actions';
import { selectFilesDraft } from '../../redux-saga/selectors';
import { isObjectEmpty } from '../../utils/isObjectEmpty';
import DropZone from '../DropZone/DropZone';
import { StyledUploadImages } from './styled';
import { IDummyData } from '../../types';

const UploadImages = ({
	chatID,
	maxFiles,
	maxSize,
	isSimpleButton,
	onHide,
	noClick,
	hiddenZone,
	dropHeight,
}: {
	chatID: string;
	maxFiles: number;
	maxSize: number;
	isSimpleButton?: boolean;
	onHide: () => void;
	noClick?: boolean;
	hiddenZone?: boolean;
	dropHeight?: number;
}) => {
	const dispatch = useDispatch();
	const draftFiles = useSelector(selectFilesDraft);

	const [isDropZoneActive, setIsDropZoneActive] = useState(false);
	const fnSet = (newFiles: IDummyData) => {
		if (!isObjectEmpty(newFiles)) {
			dispatch(inputFilesAdd(newFiles, chatID));
			dispatch(getUploadedData(newFiles, chatID));
			onHide();
			if (chatID === 'bulkMessage') {
				// @ts-ignore
				document.querySelector('textarea[name="message"]')?.focus();
			} else {
				document.getElementById(`chatTextarea-${chatID}`)?.focus();
			}
		}
	};

	return (
		<StyledUploadImages
			className={clsx(hiddenZone && 'hidden', isDropZoneActive && 'active')}
			dropHeight={dropHeight}
		>
			<DropZone
				fileType={{ ...FILE_TYPES.images, ...FILE_TYPES.files }} // parse file_types
				setFiles={fnSet}
				type={chatID}
				multiple
				currentFiles={draftFiles[chatID]}
				// @ts-ignore
				isSimpleButton={isSimpleButton}
				maxFiles={maxFiles}
				maxSize={maxSize}
				noClick={noClick}
				setIsDropZoneActive={setIsDropZoneActive}
			/>
		</StyledUploadImages>
	);
};

export default UploadImages;