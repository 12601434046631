import clsx from 'clsx';
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { toggleDrawerExtended } from '../../../redux-saga/actions';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import FlexRow from '../../atoms/FlexRow/FlexRow';
import { StyledDrawer } from './styled';
import { getDataObjectFromLocalStorage } from '../../../utils/getDataObjectFromLocalStorage';
import { LocalStorageKeys } from '../../../types';
import { CUSTOM_EVENT__CLOSE_DRAWER, SEARCH_SUBSTRING_CHAT_OPERATOR } from '../../../constants';
import { selectUserRole } from '../../../redux-saga/selectors';
import Switch from '../../molecules/Switch/Switch';
import { getIsAllowChatOperator } from '../../../utils/getIsAllowChatOperator';

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

const Drawer = ({
	absolutePositioned,
	children,
	className,
	closeIconPadding,
	destroyOnClose,
	isStatic,
	onClose,
	openedValue,
	side,
	title,
	toggleDrawer,
	width,
	withCloseIcon,
	withTitleSection,
}: {
	absolutePositioned?: boolean;
	children: React.ReactNode;
	className?: string;
	closeIconPadding?: string;
	destroyOnClose?: boolean;
	isStatic?: boolean;
	onClose?: () => void;
	openedValue: boolean;
	side?: 'left' | 'right';
	title: string;
	toggleDrawer?: (value: boolean) => void;
	width: string;
	withCloseIcon?: boolean;
	withTitleSection?: boolean;
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const isChatsPage = location.pathname.includes('/chats');
	const role = useSelector(selectUserRole);

	const { t } = useTranslation('menu');
	const theme = useTheme();

	const isAllowChatOperator = getIsAllowChatOperator(role);

	const [displayed, setDisplayed] = useState(isStatic || !destroyOnClose || false);
	const [opened, setOpened] = useState(isStatic || false);
	const targetRef = useRef(null);

	const currDrawerState = getDataObjectFromLocalStorage(LocalStorageKeys.rightDrawerState);
	const hasLocalStorageState = currDrawerState?.isExtended !== undefined;

	useEffect(() => {
		if (displayed && hasLocalStorageState) {
			dispatch(toggleDrawerExtended(currDrawerState.isExtended));
		} else {
			dispatch(toggleDrawerExtended());
		}
	}, []);

	useEffect(() => {
		const listener = () => setOpened(false);

		window.addEventListener(CUSTOM_EVENT__CLOSE_DRAWER, listener);

		return () => {
			window.removeEventListener(CUSTOM_EVENT__CLOSE_DRAWER, listener);
		};
	}, []);

	const closeDrawer = useCallback(() => {
		if (destroyOnClose) {
			setOpened(false);
			// @ts-ignore
			window.closeId = setTimeout(() => {
				setDisplayed(false);
				if (onClose) {
					onClose();
				}
			}, 350);
		} else {
			setOpened(false);
			if (onClose) {
				onClose();
			}
		}
		if (toggleDrawer) {
			toggleDrawer(false);
		}
	}, [destroyOnClose, onClose, toggleDrawer]);

	const openDrawer = useCallback(() => {
		if (destroyOnClose) {
			setDisplayed(true);
			// @ts-ignore
			window.openId = setTimeout(() => {
				setOpened(true);
				if (toggleDrawer) {
					toggleDrawer(true);
				}
			}, 100);
		} else {
			setOpened(true);
			if (toggleDrawer) {
				toggleDrawer(true);
			}
		}
		dispatch(toggleDrawerExtended(true));
	}, [destroyOnClose, toggleDrawer]);

	useEffect(() => {
		if (openedValue) {
			openDrawer();
		} else if (openedValue === false) { // do not use !openedValue
			closeDrawer();
		}

		return () => {
			// @ts-ignore
			window.clearTimeout(window.closeId);
			// @ts-ignore
			window.clearTimeout(window.openId);
		};
	}, [openedValue, closeDrawer, openDrawer]);

	useEffect(() => () => {
		// @ts-ignore
		window.clearTimeout(window.openId);
	}, []);

	const isChatOperatorInSearch = location.search.includes(SEARCH_SUBSTRING_CHAT_OPERATOR);

	const handleChangeChatOperator = () => {
		if (isChatOperatorInSearch) {
			navigate({
				search: location.search
					.replace(SEARCH_SUBSTRING_CHAT_OPERATOR, '')
					.replace('&&', '&')
					.replace('?&', '?'),
			});
		} else {
			const currentLocationSearch = location.search ? `${location.search}&` : '';
			navigate({ search: currentLocationSearch + SEARCH_SUBSTRING_CHAT_OPERATOR });
		}
	};

	return (
		displayed && (
			<StyledDrawer
				closeIconPadding={closeIconPadding}
				className={clsx(
					className,
					'drawer',
					opened && 'opened',
					displayed && 'displayed',
					side,
					isStatic && 'static',
				)}
				ref={targetRef}
				absolutePositioned={absolutePositioned}
				width={width}
			>
				{withTitleSection && (
					<FlexRow justifyContent="space-between" className="titleRow">
						{isChatsPage && isAllowChatOperator && side !== 'right' ? (
							<Wrap>
								<Typography variant="h4" text={t('myChats')} />
								<Switch
									margin="0 8px"
									handleDiameter={14}
									width={30}
									height={18}
									checked={isChatOperatorInSearch}
									onChange={handleChangeChatOperator}
								/>
								<Typography
									color={isChatOperatorInSearch
										? theme.color.general.black
										: theme.color.general.light}
									variant="h4"
									text={t('operators')}
								/>
							</Wrap>
						) : (
							<Typography variant="h3" text={title} />
						)}

						{withCloseIcon && (
							<Icon
								name="cross"
								onClick={closeDrawer}
								className="closeIcon"
								wrapperWidth={44}
								wrapperHeight={44}
								size={23}
							/>
						)}
					</FlexRow>
				)}
				{children}
			</StyledDrawer>
		)
	);
};

export default Drawer;

Drawer.defaultProps = {
	absolutePositioned: false,
	closeIconPadding: '20px',
	isStatic: false,
	side: 'right',
	destroyOnClose: true,
	withCloseIcon: true,
	withTitleSection: true,
};