import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { useTranslation } from 'react-i18next';
import { WEBSOCKET_URL_START } from '../../api';
import { EVENT_TYPE_TO_CHAT_STATUS, FILE_TYPES } from '../../constants';
import { usePrevious } from '../../hooks/useReactRedux';
import {
	getCompanyDataById,
	getCurrentSubscription,
	hideContactTyping,
	resetNewChatHistoryNotifications,
	setChatMessageIdToLastMessage,
	setContactOnlineStatus,
	setEventToChatHistory,
	setNewChatHistoryNotification,
	setNewChatHistoryNotificationsInitial,
	toggleIsUserOnline,
	verifyToken,
} from '../../redux-saga/actions';
import {
	selectChatsNotClosedInProgress,
	selectIsUserOnline,
	selectNewChatNotifications,
	selectUserCompanyId,
	selectUserMemberId,
	selectUserToken,
} from '../../redux-saga/selectors';
import { isValidJSON } from '../../utils/json';
import { getDataObjectFromLocalStorage } from '../../utils/getDataObjectFromLocalStorage';
import { LocalStorageKeys } from '../../types';

const getMessageData = (lastJsonMessage: string) => {
	let messageData;
	if (isValidJSON(lastJsonMessage)) {
		messageData = JSON.parse(lastJsonMessage);
	} else {
		messageData = lastJsonMessage;
	}

	return messageData;
};

// receives new chat events all over the app and sets "messages"
// to local storage to show notification in nav menu; receives online statuses of contacts
export const useGeneralWebsocket = (isUserOnline: boolean) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation('popupAlerts');

	const userToken = useSelector(selectUserToken);
	const userId = useSelector(selectUserMemberId);
	const newChatHistoryNotifications = useSelector(selectNewChatNotifications);
	const companyId = useSelector(selectUserCompanyId);
	const inProgressNotClosed = useSelector(selectChatsNotClosedInProgress);

	const isPrevEventChatStarted = useRef(false);

	const [messageOpenSound] = useState(() => new Audio(
		'https://goodzyk-crm.s3.eu-west-2.amazonaws.com/skibble/new_message_open.mp3',
	));
	const [messageNewSound] = useState(() => new Audio(
		'https://goodzyk-crm.s3.eu-west-2.amazonaws.com/skibble/new_message_new.mp3',
	));
	const [chatsSound] = useState(() => new Audio(
		'https://goodzyk-crm.s3.eu-west-2.amazonaws.com/skibble/new_chats.mp3',
	));

	const [skipFirstNewChatNotification, setSkipFirstNewChatNotification] = useState(false);
	// we need this cause push notification of first message
	// comes too quickly after "chat_started" event and replaces it in browser

	const { lastMessage, lastJsonMessage } = useWebSocket(
		`${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
		{
			share: true,
			// Will attempt to reconnect on all close events, such as server shutting down
			shouldReconnect() {
				dispatch(verifyToken());

				return isUserOnline;
			},
			reconnectInterval: 5000,
			reconnectAttempts: 50,
		},
		isUserOnline,
	);

	const playSound = (messageData: {
		type: string;
		status: string;
		chat_data?: {
			from_operator_id: number;
			to_operator_id: number;
		};
	}) => {
		const soundNotificationsSettings = getDataObjectFromLocalStorage(
			LocalStorageKeys.soundNotifications,
		);
		const { type, status, chat_data = {} } = messageData;
		// @ts-ignore
		const { from_operator_id, to_operator_id } = chat_data;

		if (
			(typeof soundNotificationsSettings?.chatSound !== 'boolean' || soundNotificationsSettings?.chatSound)
			&& (type === 'callback_message'
				|| type === 'chat_started'
				|| type === 'forwarded_operator'
				|| type === 'forwarded_department')
		) {
			chatsSound.play();
		} else if (
			(typeof soundNotificationsSettings?.messageSound !== 'boolean'
				|| soundNotificationsSettings?.messageSound)
			&& type === 'contact_message'
		) {
			if (
				(status === 'new' && !isPrevEventChatStarted.current)
				|| (status === 'forwarded' && to_operator_id === userId)
			) {
				messageNewSound.play();
			} else if (status === 'open' || (status === 'forwarded' && from_operator_id === userId)) {
				messageOpenSound.play();
			}
		}
	};

	const showPushNotification = (messageData: {
		type: string;
		status: string;
		chat_id: number;
		data?: {
			text: string;
			files: { type: string }[];
		};
	}) => {
		if (messageData?.type !== 'chat_started' && messageData?.type !== 'contact_message') {
			return;
		}

		const pushNotificationsSettings = getDataObjectFromLocalStorage(
			LocalStorageKeys.pushNotifications,
		);

		if (
			typeof pushNotificationsSettings?.chatPush === 'boolean'
			&& !pushNotificationsSettings?.chatPush
			&& (messageData?.type === 'chat_started' || messageData.status === 'new')
		) {
			return;
		}

		if (
			typeof pushNotificationsSettings?.messagePush === 'boolean'
			&& !pushNotificationsSettings?.messagePush
			&& messageData.status === 'open'
		) {
			return;
		}

		if (messageData?.type === 'chat_started') {
			setSkipFirstNewChatNotification(true);
		}

		if (skipFirstNewChatNotification) {
			setSkipFirstNewChatNotification(false);
			return;
		}

		const notificationTitleInner = messageData?.status === 'new'
			? `${t('newMessage')} (${t('newChat').toLowerCase()})`
			: t('newMessage');

		const notificationTitle = messageData?.type === 'chat_started'
			? t('newChat')
			: notificationTitleInner;

		const lonelyFileInMessage = !messageData?.data?.text && messageData?.data?.files?.[0];
		// @ts-ignore
		const isMessage = messageData?.type === 'message';
		const messageText = messageData?.data?.text || '';
		const lonelyFileInMessageText = FILE_TYPES.images[(lonelyFileInMessage && lonelyFileInMessage?.type) || '']
			? `📷${t('image')}`
			: `📎${t('file')}`;
		const notificationBody = isMessage
			? (messageText || (lonelyFileInMessage && lonelyFileInMessageText))
			: '';

		if (!('Notification' in window)) {
			// Check if the browser supports notifications
		} else if (Notification.permission === 'granted') {
			// eslint-disable-next-line no-new
			new Notification(notificationTitle, { body: notificationBody || '' });
		} else if (Notification.permission !== 'denied') {
			Notification.requestPermission().then((permission) => {
				if (permission === 'granted') {
					// eslint-disable-next-line no-new
					new Notification(notificationTitle, { body: notificationBody || '' });
				}
			});
		}
	};

	useEffect(() => {
		const messageData = getMessageData(lastJsonMessage);

		if (messageData?.type === 'contact_message' || messageData?.type === 'callback_message') {
			dispatch(setNewChatHistoryNotification(messageData));
		} else if (['plan_prolonged', 'plan_updated'].includes(messageData?.type)) {
			localStorage.setItem(LocalStorageKeys.subscriptionEndsAlertClosed, '');

			dispatch(getCurrentSubscription());

			if (location.pathname.includes('/customers')) {
				dispatch(getCompanyDataById(companyId || 0));
			}
		} else if (messageData?.type === 'chat_closed' && newChatHistoryNotifications?.[messageData.chat_id]) {
			dispatch(resetNewChatHistoryNotifications(messageData.chat_id));
		}

		if (messageData?.status === 'contact_offline' || messageData?.status === 'contact_online') {
			dispatch(setContactOnlineStatus(messageData.status === 'contact_online', messageData.contact_id));
		}

		if (
			document.activeElement?.id !== `chatTextarea-${messageData?.chat_id}`
			&& messageData?.type
			&& Object.keys(EVENT_TYPE_TO_CHAT_STATUS).includes(messageData.type)
			&& messageData?.type !== 'contact_typing'
		) {
			playSound(messageData);
			showPushNotification(messageData);

			if (messageData.type === 'chat_started') {
				isPrevEventChatStarted.current = true;
			} else if (isPrevEventChatStarted.current) {
				isPrevEventChatStarted.current = false;
			}
		}
	}, [lastMessage, chatsSound, messageNewSound, messageOpenSound]);

	useEffect(() => {
		const notificationsFromLS = userId
			? getDataObjectFromLocalStorage(LocalStorageKeys.newChatHistoryNotifications)?.[userId]
			: undefined;
		if (
			!location.pathname.startsWith('/chats')
			|| (!newChatHistoryNotifications && notificationsFromLS && inProgressNotClosed === false)
		) {
			dispatch(
				setNewChatHistoryNotificationsInitial(notificationsFromLS, location.pathname.startsWith('/chats')),
			);
		}
	}, [inProgressNotClosed]);

	useEffect(() => {
		if (newChatHistoryNotifications) {
			const allNotificationsFromLS = getDataObjectFromLocalStorage(
				LocalStorageKeys.newChatHistoryNotifications,
			);

			localStorage.setItem(
				LocalStorageKeys.newChatHistoryNotifications,
				JSON.stringify({ ...allNotificationsFromLS, [userId || 0]: newChatHistoryNotifications }),
			);
		}
	}, [newChatHistoryNotifications]);

	useEffect(() => {
		if (lastJsonMessage?.event_type === 'get_channel_message_id') {
			dispatch(setChatMessageIdToLastMessage({
				channelMessageId: lastJsonMessage.channel_message_id,
				messageId: lastJsonMessage.message_id,
			}));
		}
	}, [lastJsonMessage?.event_type]);
};

// receives new chat events for current chat and sets them to redux store (chat history)
export const useChatsCommonWebsocket = (chatId: number | string, contactId: number) => {
	const dispatch = useDispatch();

	const userToken = useSelector(selectUserToken);
	const isUserOnline = useSelector(selectIsUserOnline);
	const newChatHistoryNotifications = useSelector(selectNewChatNotifications);

	const prevChatId = usePrevious(chatId);

	const { lastJsonMessage, lastMessage } = useWebSocket(
		`${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
		{
			share: true,
			shouldReconnect() {
				dispatch(verifyToken());

				return isUserOnline;
			},
			reconnectInterval: 5000,
			reconnectAttempts: 50,
		},
		isUserOnline,
	);

	useEffect(() => {
		const messageData = getMessageData(lastJsonMessage);

		if (
			!messageData?.type
			|| messageData?.status === 'ok'
			|| (messageData?.type === 'contact_typing' && messageData.contact_id !== contactId)
		) {
			return;
		}

		if (messageData?.type === 'contact_not_typing' && contactId && messageData.contact_id === contactId) {
			dispatch(hideContactTyping());
		}

		if (Object.keys(EVENT_TYPE_TO_CHAT_STATUS).includes(messageData.type)) {
			// receives contact's message and writes it to current chat's chat history
			dispatch(setEventToChatHistory(messageData.type, messageData, true));

			if (messageData?.type === 'chat_closed') {
				dispatch(resetNewChatHistoryNotifications(messageData.chat_id));
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (chatId && newChatHistoryNotifications?.[chatId]) {
			dispatch(resetNewChatHistoryNotifications(chatId));
		}
	}, [chatId, newChatHistoryNotifications, prevChatId]);
};

export const useSendEventWebsocket = (
	onSend: (callback: () => void) => void,
	onSuccess: (event: string, callback?: () => void) => void,
	setEventJustReceived?: (eventType: string) => void,
) => {
	const dispatch = useDispatch();
	const userToken = useSelector(selectUserToken);
	const isUserOnline = useSelector(selectIsUserOnline);

	const [eventInProgress, setEventInProgress] = useState('');
	const [eventSentStatus, setEventSentStatus] = useState(''); // loading, ok, error

	const {
		sendMessage, sendJsonMessage, lastJsonMessage, lastMessage, readyState,
	} = useWebSocket(
		`${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
		{
			share: true,
			shouldReconnect() {
				dispatch(verifyToken());

				return isUserOnline;
			},
			reconnectInterval: 5000,
			reconnectAttempts: 50,
		},
		isUserOnline,
	);

	const onSendEvent = (eventType: string) => () => {
		if (readyState !== 1) {
			dispatch(toggleIsUserOnline(true));

			setTimeout(() => {
				onSend(() => {
					setEventInProgress(eventType);
					setEventSentStatus('inProgress');
				});
			}, 1000);
		} else if (!eventInProgress) {
			onSend(() => {
				setEventInProgress(eventType);
				setEventSentStatus('inProgress');
			});
		}
	};

	useEffect(() => {
		const messageData = getMessageData(lastJsonMessage);

		if (['operator_typing', 'operator_not_typing'].includes(messageData?.type)) {
			return;
		}

		if (
			messageData?.type
			&& Object.keys(EVENT_TYPE_TO_CHAT_STATUS).includes(messageData.type)
			&& setEventJustReceived
		) {
			setEventJustReceived(messageData.type);
		}

		if (messageData?.status) {
			if (['ok', 'error', 'operator_connected'].includes(messageData.status)) {
				setEventSentStatus(messageData.status);
			}

			if (messageData.status === 'error') {
				setEventInProgress('');
			}

			if ((messageData.status === 'ok' || messageData.status === 'contact_offline') && onSuccess) {
				onSuccess(eventInProgress, () => {
					setEventSentStatus('');
					setEventInProgress('');
				});
			}
		}
	}, [eventInProgress, lastMessage]);

	return {
		sendEventToServer: sendJsonMessage,
		sendMessage,
		onSendEvent,
		eventSentStatus,
		eventInProgress,
	};
};