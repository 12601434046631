import clsx from 'clsx';
import React, { forwardRef, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { useDebounce } from '../../../hooks/useDebounce';
import { isObjectEmpty } from '../../../utils/isObjectEmpty';
import Icon, { IIconProps } from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import QuantityInput from '../QuantityInput/QuantityInput';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import {
	StyledButtonsRow,
	StyledErrorLabel,
	StyledInput,
	StyledInputWrapper,
	StyledLabel,
	StyledWrapper,
} from './styled';

interface IProps {
	children?: React.ReactNode;
	className?: string;
	id?: string;
	disabled?: boolean;
	error?: string | boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	field?: any;
	fullWidth?: boolean;
	iconLeftProps?: IIconProps;
	iconRightProps?: IIconProps;
	initialValue?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inputRef?: any;
	insideButtons?: React.ReactNode;
	replyMessage?: React.ReactNode;
	isOneNumberInput?: boolean;
	label?: string;
	labelType?: 'top' | 'border';
	labelWidth?: string;
	maxLength?: number;
	multiline?: boolean;
	name?: string;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onEnter?: (e: string) => void;
	onSubmit?: (e: string) => void;
	placeholder?: string;
	placeholderColor?: string;
	required?: boolean;
	rows?: number;
	submitByEnterPressed?: boolean;
	success?: boolean;
	type?: 'text' | 'password' | 'number' | 'richText' | 'time';
	value?: string | number;
	withDebounce?: boolean;
	withQuantityButtons?: boolean;
	withoutBorder?: boolean;
	withoutValidation?: boolean;
}

const ReplyMessageWrap = styled.div`
  padding: 10px;
`;

const Input = forwardRef((props: IProps, ref) => {
	const {
		children,
		className,
		disabled,
		error,
		field,
		fullWidth,
		iconLeftProps,
		iconRightProps,
		inputRef,
		insideButtons,
		replyMessage,
		isOneNumberInput,
		label,
		labelType,
		maxLength,
		multiline,
		name,
		onBlur,
		onChange,
		onEnter,
		onSubmit,
		placeholder,
		placeholderColor,
		required,
		rows,
		submitByEnterPressed = true,
		success,
		type,
		value,
		withDebounce,
		withQuantityButtons,
		withoutBorder,
		withoutValidation,
		...otherProps
	} = props;
	const theme = useTheme();
	const debouncedValue = useDebounce(value, 300);

	const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange && !withDebounce) {
			onChange(e);
		}
	};

	useEffect(() => {
		if (onChange && withDebounce) {
			// @ts-ignore
			onChange(debouncedValue);
		}
	}, [withDebounce, debouncedValue, onChange]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!disabled && submitByEnterPressed && (onEnter || onSubmit) && e.key === 'Enter') {
			if (onSubmit) {
				// @ts-ignore
				onSubmit(e.target.value);
			}
			if (onEnter) {
				// @ts-ignore
				onEnter(e.target.value);
			}
		}
	};

	const hasLeftIcon = iconLeftProps && !isObjectEmpty(iconLeftProps);
	const hasRightIcon = iconRightProps && !isObjectEmpty(iconRightProps);

	const getInputField = () => {
		const classes = clsx(
			!!error && 'hasError',
			success && 'success',
			hasLeftIcon && 'hasLeftIcon',
			hasRightIcon && 'hasRightIcon',
			multiline && 'multiline',
			withQuantityButtons && 'withQuantityButtons',
			isOneNumberInput && 'isOneNumberInput',
		);

		if (type === 'number') {
			return (
				// @ts-ignore
				<QuantityInput
					inputClassName={classes}
					isOneNumberInput={isOneNumberInput}
					withQuantityButtons={withQuantityButtons}
					maxLength={maxLength}
					{...props}
				/>
			);
		}
		if (type === 'richText' && typeof window !== 'undefined') {
			// @ts-ignore
			return <RichTextEditor inputClassName={classes} {...props} />;
		}

		return (
			<StyledInput
				onKeyDown={handleKeyDown}
				// @ts-ignore
				as={multiline && 'textarea'}
				name={name}
				disabled={disabled}
				value={value}
				onBlur={onBlur}
				onChange={onChangeState}
				placeholder={placeholder}
				placeholderColor={placeholderColor}
				type={type}
				className={classes}
				ref={inputRef}
				rows={rows}
				maxLength={maxLength}
				{...otherProps}
			/>
		);
	};
	const errorColor = error ? theme.color.status.error : theme.color.general.black;
	const fillColor = disabled ? theme.color.general.lighter : errorColor;

	return (
		<StyledInputWrapper
			className={clsx(className, withoutValidation && 'withoutValidation', 'inputContainer')}
		>
			{label && labelType === 'top' && (
				<StyledLabel
					as={Typography}
					variant="caption1"
					className={clsx(required && 'required', 'label', 'top')}
				>
					{label}
				</StyledLabel>
			)}
			<StyledWrapper
				// @ts-ignore
				ref={ref}
				fullWidth={fullWidth}
				type={type}
				className={clsx(
					'inputWrapper',
					disabled && 'disabled',
					!!error && 'hasError',
					success && 'success',
					insideButtons && 'withInsideButtons',
					withoutBorder && 'withoutBorder',
				)}
			>
				{replyMessage ? (
					<ReplyMessageWrap>{replyMessage}</ReplyMessageWrap>
				) : null}
				{label && labelType === 'border' && (
					<StyledLabel
						className={clsx(required && 'required', 'label', 'border')}
						isUppercase={field?.isUppercase}
					>
						{label?.toLowerCase()}
					</StyledLabel>
				)}
				{!!iconLeftProps && (
					<Icon
						width={16}
						height={16}
						className="leftIcon"
						fill={fillColor}
						{...iconLeftProps}
					/>
				)}

				{getInputField()}

				{insideButtons && <StyledButtonsRow className="buttonsRow">{insideButtons}</StyledButtonsRow>}

				{!!iconRightProps && (
					<Icon
						size={15}
						className="rightIcon"
						fill={fillColor}
						{...iconRightProps}
					/>
				)}

				{success && (
					<Icon
						name="checkRounded"
						borderRadius="50%"
						wrapperHeight={14}
						wrapperWidth={14}
						width={8}
						className="rightIcon success"
					/>
				)}

				{children}
			</StyledWrapper>
			{!!error && typeof error === 'string' && (
				<StyledErrorLabel className="error">
					{error}
				</StyledErrorLabel>
			)}
		</StyledInputWrapper>
	);
});

Input.defaultProps = {
	error: '',
	isOneNumberInput: true,
	labelType: 'border',
	type: 'text',
	withQuantityButtons: false,
};

export default Input;