import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { inputFileRemove } from '../../redux-saga/actions';
import { selectFilesUpload } from '../../redux-saga/selectors';
import UploadedItem from './UploadedItem';
import { StyledUploadedList } from './styled';

const UploadedItemsList = ({
	chatID, uploadRef, onHide, className, stateKey,
}: {
	chatID: string;
	uploadRef: React.RefObject<HTMLDivElement>;
	onHide: () => void;
	className?: string;
	stateKey?: string;
}) => {
	const dispatch = useDispatch();

	const {
		filesChosen, filesUploaded, filesInProgress, errors,
	} = useSelector(selectFilesUpload);
	const files = stateKey === 'filesUploaded' ? filesUploaded[chatID] : filesChosen[chatID];

	const fnDelete = (id: number) => {
		dispatch(inputFileRemove(id, chatID, stateKey));
		onHide();
	};

	return (
		<StyledUploadedList ref={uploadRef} className={clsx(className, 'uploadItemsListContainer')}>
			<aside className="uploadItemsList">
				{files
					&& files.map((file: {
						preview: string;
						name: string;
						size: number;
						file_name: string;
						file_size: number;
					}) => (
						<UploadedItem
							error={errors[chatID]?.includes(file.preview)}
							// @ts-ignore
							fnDelete={fnDelete}
							key={file.preview}
							loading={filesInProgress[chatID]?.includes(file.preview)}
							src={file.preview}
							fileName={file.name || file.file_name}
							fileSize={file.size || file.file_size}
						/>
					))}
			</aside>
		</StyledUploadedList>
	);
};

export default UploadedItemsList;