import { Location } from 'react-router-dom';
import {
	CANCEL_SUBSCRIPTION,
	CONFIRM_EMAIL,
	DELETE_COMPANY,
	DELETE_ENTITY,
	FILE_UPLOAD,
	FIND_ALL_MESSAGES,
	GET_BILLING_PLANS,
	GET_CHATS_LIST,
	GET_CHAT_DETAILS,
	GET_CHAT_DETAILS_ON_SCROLL,
	GET_COMPANY_DATA,
	GET_CURRENT_SUBSCRIPTION,
	GET_CURRENT_USER,
	GET_INVITATION_LINK,
	GET_INVITE_DETAILS,
	GET_OPEN_DATA,
	GET_TABLE_DATA,
	GET_TABLE_ITEM_DETAILS,
	HIDE_CONTACT_TYPING,
	INITIALIZE_APP,
	INPUT_FILES_ADD,
	INPUT_FILES_CLEAR,
	INPUT_FILE_REMOVE,
	INVITATIONS_RESEND,
	INVITATIONS_SEND,
	POPUP_ALERT_HIDE,
	POPUP_ALERT_HIDE_ALL,
	POPUP_ALERT_SHOW,
	REQUEST_RESET_PASSWORD,
	RESET_FOUND_MESSAGES,
	RESET_NEW_CHAT_HISTORY_NOTIFICATIONS,
	RESET_PASSWORD,
	SEARCH_CHAT_MESSAGE,
	SET_CHAT_MESSAGE,
	SET_CONTACT_NAME_TO_CHAT,
	SET_CONTACT_ONLINE_STATUS,
	SET_DATA_TYPE,
	SET_EVENT_TO_CHAT_HISTORY,
	SET_FORM_VALUES,
	SET_IS_EVENT_JUST_SENT,
	SET_NEW_CHAT_HISTORY_NOTIFICATION,
	SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL,
	SIDEBAR_ITEM_RESET,
	SIDEBAR_ITEM_SET,
	SIDEBAR_UPSERT,
	SIGN_IN,
	SIGN_OUT,
	SIGN_UP,
	TOGGLE_DRAWER_EXTENDED,
	TOGGLE_IS_USER_ONLINE,
	TOGGLE_NAV_MENU_EXTENDED,
	UPDATE_COMPANY_DATA,
	UPDATE_CURRENT_PLAN,
	VERIFY_TOKEN,
	KNOWLEDGE_BASE_UPDATE_POSITION,
	GET_CHATS_COUNT_NEW,
	DELETE_CHAT,
	REPLIED_MESSAGE,
	SET_CHAT_MESSAGE_ID_TO_LAST_MESSAGE,
} from './constants';
import {
	IApiSchema,
	IDummyData,
	IKnowledgeBaseUpdatePositionServerRequest,
	TNavigate,
} from '../types';
import { IIconProps } from '../atomic-design-components/atoms/Icon/Icon';

// COMMON

export const popupAlertShow = ({
	id = Date.now().toString(),
	type,
	contentKey,
	contentParams,
	content,
	iconName,
	iconProps,
	stacked,
	withCloseButton,
	className,
	onCloseCustom,
	timeout = 4000,
}: {
	id?: string;
	type: 'default' | 'new' | 'success' | 'warning' | 'error';
	contentKey?: string;
	withCloseButton: boolean;
	iconName: string;
	contentParams?: { email?: string, fileName?: string };
	content?: string | JSX.Element
	iconProps?: IIconProps;
	stacked?: boolean;
	className?: string;
	onCloseCustom?: () => void;
	timeout?: number;
}) => ({
	type: POPUP_ALERT_SHOW,
	payload: {
		id,
		type,
		contentKey,
		contentParams,
		content,
		iconName,
		iconProps,
		timeout,
		stacked,
		withCloseButton,
		className,
		onCloseCustom,
	},
});

export const popupAlertHide = (id: string) => ({ type: POPUP_ALERT_HIDE, id });

export const popupAlertHideAll = () => ({ type: POPUP_ALERT_HIDE_ALL });

export const initializeApp = (
	navigate: (str: string) => void,
	location: Location,
	changeLanguage: (str: string) => void,
) => ({
	type: INITIALIZE_APP,
	navigate,
	location,
	changeLanguage,
});

export const verifyToken = () => ({ type: VERIFY_TOKEN });

export const toggleNavMenuExtended = (extendedValue?: boolean) => ({
	type: TOGGLE_NAV_MENU_EXTENDED,
	extendedValue,
});

export const toggleDrawerExtended = (extendedValue?: string | boolean) => (
	{ type: TOGGLE_DRAWER_EXTENDED, extendedValue }
);

// DATA

export const setDataType = (type: string) => ({ type: SET_DATA_TYPE, payload: { type } });

export const updateKnowledgeBasePosition = (
	payload: {
		request: IKnowledgeBaseUpdatePositionServerRequest,
	},
) => ({
	type: KNOWLEDGE_BASE_UPDATE_POSITION,
	payload: {
		request: payload.request,
	},
});

export const getTableData = ({
	type,
	search,
	searchFields,
	sortString,
	queryParams,
	pageLimit,
	pageOffset,
	isSystemData,
	status,
	license_ids,
	noReduxSet,
	apiUrlParam,
	id,
}: {
	type: string,
	isSystemData?: boolean,
	search?: string,
	searchFields?: string,
	sortString?: string,
	license_ids?: string,
	license_id?: string,
	queryParams?: { key: string, value: string | number | string[] }[],
	pageLimit?: string,
	pageOffset?: IDummyData,
	status?: string,
	noReduxSet?: string,
	apiUrlParam?: string,
	id?: string,
}) => ({
	type: GET_TABLE_DATA,
	payload: {
		type,
		search,
		searchFields,
		sortString,
		queryParams,
		pageLimit,
		pageOffset,
		isSystemData,
		status,
		license_ids,
		noReduxSet,
		apiUrlParam,
		id,
	},
});

export const getOpenData = (
	{ type, queryParams }: { type: string, queryParams: { key: string, value: string }[] },
) => ({
	type: GET_OPEN_DATA,
	payload: { type, queryParams },
});

// SIDEBAR

export const getTableItemDetails = (id: string, type: string) => ({
	type: GET_TABLE_ITEM_DETAILS,
	payload: { type, id },
});

export const upsertSidebarItem = (data: unknown) => ({ type: SIDEBAR_UPSERT, payload: data });

export const sidebarItemReset = () => ({ type: SIDEBAR_ITEM_RESET });

export const sidebarItemSet = (item: unknown) => ({ type: SIDEBAR_ITEM_SET, item });

export const sendInvitations = (requestBody: unknown) => ({ type: INVITATIONS_SEND, requestBody });

export const resendInvitations = (id: string) => ({ type: INVITATIONS_RESEND, id });

export const getInvitationLink = (id: string) => ({ type: GET_INVITATION_LINK, id });

export const deleteEntity = (data: unknown) => ({ type: DELETE_ENTITY, payload: data });

// COMPANY, BILLING

export const getCompanyDataById = (id?: number) => ({ type: GET_COMPANY_DATA, id });

export const updateCompanyDataById = (id: number, values: unknown) => ({
	type: UPDATE_COMPANY_DATA, id, values,
});

export const getBillingPlans = () => ({ type: GET_BILLING_PLANS });

export const updateCurrentPlan = ({
	customer_id,
	plan_id,
	expires_at,
	start_at,
	billing_period,
	payment_type,
	status,
	navigate,
	location,
}: {
	customer_id: number | string,
	plan_id: number | string,
	expires_at: string | null,
	start_at: string,
	billing_period?: string,
	status: string,
	payment_type: string,
	navigate: TNavigate,
	location: Location,
}) => ({
	type: UPDATE_CURRENT_PLAN,
	payload: {
		customer_id, plan_id, expires_at, start_at, billing_period, status, payment_type,
	},
	navigate,
	location,
});

export const cancelSubscription = () => ({ type: CANCEL_SUBSCRIPTION });

export const deleteCompany = (customerId: number, navigate: TNavigate) => ({
	type: DELETE_COMPANY, customerId, navigate,
});

// USER AND AUTH

export const getCurrentUser = (
	navigate: TNavigate,
	location: Location,
	changeLanguage: (path: string) => void,
) => ({
	type: GET_CURRENT_USER,
	navigate,
	location,
	changeLanguage,
});

export const getCurrentSubscription = () => ({ type: GET_CURRENT_SUBSCRIPTION });

export const userSignUp = (credentials: {
	company_name: string,
	company_phone: string,
	email: string,
	full_name: string,
	password: string,
	code: string,
}, navigate: TNavigate) => {
	const {
		company_name, company_phone, email, full_name, password, code,
	} = credentials;

	return {
		type: SIGN_UP,
		payload: {
			email, password, full_name, company_name, company_phone, code,
		},
		navigate,
	};
};

export const userSignIn = (credentials: unknown, navigate?: TNavigate) => ({
	type: SIGN_IN,
	payload: credentials,
	navigate,
});

export const userLogout = () => ({ type: SIGN_OUT });

export const getInviteDetails = (code: string) => ({ type: GET_INVITE_DETAILS, payload: { code } });

export const userConfirm = (userCode: string) => ({
	type: CONFIRM_EMAIL,
	payload: { code: userCode },
});

export const userRequestResetPassword = (email: string, navigate?: TNavigate) => ({
	type: REQUEST_RESET_PASSWORD,
	payload: { email },
	navigate,
});

export const userResetPassword = (
	isUpdate: boolean,
	credentials: IDummyData,
	navigate: TNavigate,
) => ({
	type: RESET_PASSWORD,
	isUpdate,
	payload: credentials,
	navigate,
});

export const toggleIsUserOnline = (isOnline: boolean) => ({
	type: TOGGLE_IS_USER_ONLINE, payload: { isOnline },
});

// CHATS

export const deleteChat = (chatId: number) => ({
	type: DELETE_CHAT,
	payload: { chatId },
});

export const setRepliedMessage = (payload: IApiSchema['IChatEventBrief'] | null) => ({
	type: REPLIED_MESSAGE,
	payload,
});

export const getChatsList = (
	queryParams?: { key: string, value: string }[],
	isInfiniteScroll?: boolean,
	searchValue?: string,
	onlyClosed?: boolean,
) => ({
	type: GET_CHATS_LIST,
	payload: {
		queryParams, isInfiniteScroll, searchValue, onlyClosed,
	},
});

export const setChatMessageIdToLastMessage = (payload: {
	channelMessageId: number,
	messageId: number,
}) => ({
	type: SET_CHAT_MESSAGE_ID_TO_LAST_MESSAGE,
	payload,
});

export const setChatsCountNew = (count: number) => ({
	type: GET_CHATS_COUNT_NEW,
	payload: count,
});

export const setContactNameToChat = (
	{ contact_name, chat_id }:
		{ contact_name: string, chat_id: number },
) => ({
	type: SET_CONTACT_NAME_TO_CHAT,
	payload: { contact_name, chat_id },
});

export const setEventToChatHistory = (
	type: string,
	data: IDummyData,
	isReceivedEvent?: boolean,
) => ({
	type: SET_EVENT_TO_CHAT_HISTORY,
	payload: { data: data.at ? data : { ...data, at: new Date() }, type, isReceivedEvent },
});

export const hideContactTyping = () => ({
	type: HIDE_CONTACT_TYPING,
});

export const getChatDetails = ({ chatId }: { chatId?: number | string }) => ({
	type: GET_CHAT_DETAILS,
	payload: { chatId },
});

export const getChatDetailsOnScroll = ({
	thresholdMessageId, isScrollUp, searchValue, before,
}: {
	thresholdMessageId?: number, isScrollUp: boolean, searchValue?: string, before?: string,

}) => ({
	type: GET_CHAT_DETAILS_ON_SCROLL,
	payload: {
		thresholdMessageId, isScrollUp, searchValue, before,
	},
});

export const findAllMessages = (chatId: number, searchValue: string) => ({
	type: FIND_ALL_MESSAGES,
	payload: { chatId, searchValue },
});

export const searchChatMessage = (chatId: number, messageId: number) => ({
	type: SEARCH_CHAT_MESSAGE,
	payload: { chatId, messageId },
});

export const resetFoundMessages = () => ({ type: RESET_FOUND_MESSAGES });

export const setNewChatHistoryNotificationsInitial = (
	dataFromLocalStorage: unknown,
	withClosedChatsFilter: unknown,
) => ({
	type: SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL,
	payload: { dataFromLocalStorage, withClosedChatsFilter },
});

export const setNewChatHistoryNotification = (event: unknown) => ({
	type: SET_NEW_CHAT_HISTORY_NOTIFICATION,
	payload: { event },
});

export const resetNewChatHistoryNotifications = (chatId: number | string) => ({
	type: RESET_NEW_CHAT_HISTORY_NOTIFICATIONS,
	payload: { chatId },
});

export const setContactOnlineStatus = (isOnline: boolean, contactId: number) => ({
	type: SET_CONTACT_ONLINE_STATUS,
	payload: { isOnline, contactId },
});

export const setChatMessage = (message: string) => ({
	type: SET_CHAT_MESSAGE, payload: { message },
});

export const setEventJustSent = (eventJustSent: unknown) => ({
	type: SET_IS_EVENT_JUST_SENT,
	payload: { eventJustSent },
});

// FILES

export const getUploadedData = (
	newFiles: Blob | File[],
	chatID: number | string,
	memberID?: number,
	location?: Location,
) => ({
	type: FILE_UPLOAD,
	newFiles,
	chatID,
	memberID,
	location,
});

// LOCAL FILES ACTIONS

export const inputFilesAdd = (files: (IApiSchema['IS3File'] | File)[], chatID: number | string, stateKey = 'filesChosen') => ({
	type: INPUT_FILES_ADD,
	files,
	chatID,
	stateKey,
});

export const inputFileRemove = (id: number, chatID: number | string, stateKey = 'filesChosen') => ({
	type: INPUT_FILE_REMOVE,
	filePreviewId: id,
	chatID,
	stateKey,
});

export const inputFilesClear = () => ({ type: INPUT_FILES_CLEAR });

// FORM

export const setFormValues = (values: unknown, formName?: string) => ({
	type: SET_FORM_VALUES,
	payload: { values, formName },
});