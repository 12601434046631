import theme from './theme';
import { clientTz, findTzByName } from './timezoneSelect';
import { IApiSchema } from './types';

export const CURRENCY: Record<string, string> = { UAH: '₴', USD: '$', EUR: '€' };

export const LIMIT_TEXT_MESSAGE_FOR_LEFT_DIRECTION_DROPDOWN_MENU = 15;

export const SEARCH_VALUE_MIN_LENGTH = 2;
export const MAX_SEARCH_INPUT_LENGTH = 255;

export const LIMIT_KNOWLEDGE_BASE_FOLDER_LENGTH = localStorage.getItem('debugKBLimit') ? 10 : 15;
export const LIMIT_KNOWLEDGE_BASE_ARTICLE_LENGTH = localStorage.getItem('debugKBLimit') ? 10 : 100;

export const NAV_MENU_WIDTH = {
	desktop: { extended: '260px', condensed: '64px' },
	mobile: { extended: '100%', condensed: '100%' },
};

export const NAV_MENU_COLORS = {
	system_administrator: {
		dark: theme.color.secondary.darker,
		light: theme.color.secondary.lightest,
		lighter: theme.color.secondary.lighter,
	},
	customer_administrator: {
		dark: theme.color.tertiary.dark,
		light: theme.color.tertiary.lightest,
		lighter: `${theme.color.status.success}40`,
	},
	customer_owner: {
		dark: theme.color.tertiary.dark,
		light: theme.color.tertiary.lightest,
		lighter: `${theme.color.status.success}40`,
	},
	operator: {
		dark: theme.color.general.darker,
		light: theme.color.general.lighter,
		lighter: `${theme.color.general.dark}26`,
	},
};

export const MAX_CONTEXT_LENGTH_KNOWLEDGE_BASE = 1800;

export const MAX_INPUT_LENGTH: Record<string, number> = {
	templateName: 100,
	templateValue: 1500,
	templateSearch: 50,
	chatsSearch: 50,
	chatsValue: 1500,
	chatsWidgetToken: 100,
	chatsWidgetName: 100,
	bulkMessagingSearchLimit: 50,
	nameBulkMsgLimit: 100,
	messageBulkMsgLimit: 1500,
	newTagValueLimit: 15,
	tagsLimit: 250,
	commentLimit: 1000,
	fullName: 70,
	emailLimit: 50,
	membersSearch: 50,
	membersEmailLimit: 50,
	membersNameLimit: 70,
	customersSearch: 50,
	customersNewTag: 15,
	customersTagLimit: 250,
	customersComment: 1000,
	customersEmail: 50,
	contactSearchLimit: 50,
	departmentSearch: 50,
	departmentName: 100,
	knowledgeBaseSearch: 50,
	knowledgeBaseFolderName: 200,
	knowledgeBaseArticleTitle: 200,
	knowledgeBaseArticleValue: MAX_CONTEXT_LENGTH_KNOWLEDGE_BASE,
	newKeywordsLimit: 15,
	knowledgeBaseKeywordsLimit: 250,
	knowledgeBaseLink: 1000,
	subscriptionPlanSearch: 50,
	subscriptionPlanName: 100,
	settingProfileName: 70,
	myCompanyName: 100,
	myCompanyDescription: 500,
	myCompanyEmail: 50,
	limitWidgetName: 100,
};

export const WIDGET_COLORS: Record<string, string> = {
	orange: '#FF5F00',
	classic: '#0057B7',
	gold: '#FAB711',
	coral: '#FF6F61',
	cherry: '#BB2649',
	violet: '#5F4B8B',
	grass: '#88B04B',
	turquoise: '#367E7D',
	corporate: '#0F4C81',
};

export const HEADER_HEIGHT = 64;

export const NO_FOLDER = 'no_folder';

export const STATUSES_ICONS: Record<string, string> = {
	pending: 'statusPending',
	active: 'statusActive',
	inactive: 'statusDeleted',
	pending_inactive: 'pendingInactive',
};

export const STATUSES_ICONS_KNOWLEDGE_BASE: Record<string, string> = {
	active: 'statusActive',
	deleted: 'statusDeleted',
	disabled: 'statusDeleted',
};

export const CUSTOMERS_ADDITIONAL_STATUS_ICON: Record<string, string> = {
	trial: 'statusTrial',
};

export const PAYMENTS_STATUSES_ICONS: Record<string, string> = {
	pending: 'statusPending',
	inProcessing: 'statusPending',
	approved: 'statusActive',
	declined: 'statusDeleted',
	expired: 'statusDeleted',
};

export const BULK_MESSAGE_STATUSES_ICONS: Record<string, string> = {
	draft: 'statusPending',
	in_progress: 'statusInProgress',
	scheduled: 'calendar2',
	complete: 'statusActive',
	complete_with_error: 'statusActiveWithError',
};

export const ALL_STATUSES_ICONS: Record<string, string> = {
	...STATUSES_ICONS,
	...CUSTOMERS_ADDITIONAL_STATUS_ICON,
};

export const SEARCH_FIELDS: Record<string, string> = {
	members: 'full_name|email|role',
	contacts: 'full_name|email|id',
	customers: 'company_name|alias',
	departments: 'name',
	bulkMessaging: 'name',
	templates: 'name|body',
	subscriptionPlans: 'titles',
};

export const MESSENGERS_INFO: Record<string, {
	presetPart: string,
}> = {
	telegram: {
		presetPart: 'https://t.me/',
	},
	viber: {
		presetPart: '',
	},
	instagram: {
		presetPart: 'https://www.instagram.com/',
	},
	facebook: {
		presetPart: 'https://m.me/',
	},
	whatsapp: {
		presetPart: '',
	},
	website_url: {
		presetPart: 'https://',
	},
};

export const ROLES = {
	system_administrator: true,
	customer_administrator: true,
	customer_owner: true,
	operator: true,
};

export const ROUTES_FORBIDDEN = {
	operator: [
		'/my-company',
		'/members',
		'/contacts',
		'/customers',
		'/payments',
		'/bulk-messaging',
		'/knowledgebase',
	],
	customer_administrator: [
		'/customers',
		'/payments',
		'/my-company/subscription',
		'/my-company/delete-company',
	],
	customer_owner: ['/customers'],
	system_administrator: [],
};

export const CHANNELS = ['live_chat', 'callback', 'telegram', 'viber', 'instagram', 'facebook', 'whatsapp'];
export const MESSENGERS = [
	{ id: 'telegram', label: 'Telegram' },
	{ id: 'viber', label: 'Viber' },
	{ id: 'instagram', label: 'Instagram' },
	{ id: 'facebook', label: 'Facebook' },
	{ id: 'whatsapp', label: 'Whatsapp' },
];
export const ALL_CHANNELS = [
	{ id: 'live_chat', label: 'Live Chat' },
	{ id: 'callback', label: 'Callback' },
	...MESSENGERS,
];

export const EVENT_TYPE_TO_CHAT_STATUS = {
	chat_started: 'new',
	chat_closed: 'closed',
	forwarded_operator: '',
	forwarded_department: '',
	operator_joined: 'open',
	operator_rated: 'closed',
	contact_message: '',
	operator_message: '',
	system_message: '',
	contact_typing: '',
	callback_message: '',
};

export const FILE_TYPES: {
	images: Record<string, string[]>,
	files: Record<string, string[]>,
} = {
	images: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
	files: {
		'application/pdf': ['.pdf'],
		'text/plain': ['.txt'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
		'application/vnd.ms-powerpoint': ['.ppt'],
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
		'image/heic': ['.heic'],
	},
};

export const NO_ACTIVE_CHANNELS_WARNING_ID = 'noActiveChannelsWarning';

export const INDUSTRIES = [
	'general',
	'ecommerce',
	'logistics',
	'finance',
	'education',
	'travel',
	'real_estate',
	'construction',
	'it',
	'medical',
];

export const DEFAULT_WORK_HOURS = {
	timezone_offset: findTzByName(clientTz())?.offset,
	timezone_region: findTzByName(clientTz())?.value,
	work_hours: {
		monday: { is_open: true, start: '00:00', close: '23:59' },
		tuesday: { is_open: true, start: '00:00', close: '23:59' },
		wednesday: { is_open: true, start: '00:00', close: '23:59' },
		thursday: { is_open: true, start: '00:00', close: '23:59' },
		friday: { is_open: true, start: '00:00', close: '23:59' },
		saturday: { is_open: true, start: '00:00', close: '23:59' },
		sunday: { is_open: true, start: '00:00', close: '23:59' },
	},
};

export const DEFAULT_WIDGET_SETTINGS = {
	color: theme.color.primary.main,
	is_hide_branding: false,
	button_style: 'typeSkibble',
	button_placement: 'right',
	callback_button_style: 'typeCallback',
	callback_button_placement: 'left',
	is_form_displayed: false,
	form_fields: {
		name: { is_visible: false, is_required: false },
		email: { is_visible: false, is_required: false },
		phone: { is_visible: false, is_required: false },
	},
	departments: {
		is_selected: false,
		values: [],
	},
	privacy_policy: {
		is_required: false,
		document_url: '',
	},
};

export const WIDGET_BUTTON_ICONS = [
	'typeSkibble',
	'typeBubbles',
	'typeBubble',
	'typeBriefcase',
	'typeQuestion',
];

export const BOTS_URLS_START: Record<string, string> = {
	telegram: 'https://t.me/',
	viber: 'viber://pa?chatURI=',
	whatsapp: 'https://wa.me/',
	instagram: 'https://ig.me/m/',
	facebook: 'https://m.me/',
};

export const SUPPORTED_CURRENCIES = ['UAH', 'USD', 'EUR'];

export const COMPANY_NOTIFICATIONS = {
	no_response: {
		status: 'active',
		delay: 60,
		chat_owner: true,
		administrators: false,
		specified_emails: false,
		emails: [],
	},
	new_chat_working_hours: {
		status: 'inactive',
		delay: 60,
		operators: true,
		administrators: false,
		specified_emails: false,
		emails: [],
	},
	new_chat_non_working_hours: {
		status: 'inactive',
		operators: true,
		administrators: false,
		specified_emails: false,
		emails: [],
	},
};

export const CUSTOM_EVENT__CLOSE_DRAWER = 'CUSTOM_EVENT__CLOSE_DRAWER';

export const FILE_SIZE_LIMIT = 20971520;

export const EMAIL_REG_EXP = /^[\w+]+([.-]?[\w+]+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

export const SEARCH_SUBSTRING_CHAT_OPERATOR = 'chat_type=operator';
export const SEARCH_KEY_MEMBER_ID = 'member_id';

export const LIMIT_REPLAY_MESSAGE_TEXT = 120;

export const ALLOWED_REPLY_TO_MESSAGE_CHANNELS: IApiSchema['ChatChannelType'][] = [
	'live_chat',
	'facebook',
	'instagram',
	'telegram',
	'whatsapp',
];