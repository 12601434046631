import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { StyledMenuItem } from './styled';
import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Link from '../../atomic-design-components/atoms/Link/Link';
import Badge from '../../atomic-design-components/atoms/Badge/Badge';
import Tooltip from '../../atomic-design-components/atoms/Tooltip/Tooltip';
import Tag from '../../atomic-design-components/atoms/Tag/Tag';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { sortObjectToUrlString } from '../TableBlock/hooks/useSorting';
import { selectChatsCounts } from '../../redux-saga/selectors';
import { getIsChatOperator } from '../../utils/getIsChatOperator';

const MenuItem = ({
	iconName,
	iconFill,
	text,
	route,
	isActive,
	isAlphaVersion,
	isFreePlan,
	className,
	location,
	to,
	onClick,
	isExtended,
	isMobile,
	activeBGColor,
	sortObject,
}: {
	iconName?: string;
	iconFill?: string;
	text?: string;
	route?: string;
	isActive?: boolean;
	isAlphaVersion?: boolean;
	isFreePlan?: boolean;
	className?: string;
	location?: Location;
	to?: string;
	onClick?: () => void;
	isExtended?: boolean;
	isMobile?: boolean;
	activeBGColor?: string;
	sortObject?: Record<string, string>;
}) => {
	const theme = useTheme();
	const { t } = useTranslation(['menu', 'all']);

	const isChatOperator = getIsChatOperator();

	const isMenuItemDisabled = isFreePlan && (route === '/bulk-messaging' || to === '/knowledgebase');

	const routeWithSortString = sortObject
		? `${route || to}?sort=${sortObjectToUrlString(sortObject)}`
		: route || to;

	const chatCounts = useSelector(selectChatsCounts);
	const newChatNotificationsCount = chatCounts.new;

	return (
		<Tooltip
			left={route === '/bulk-messaging' ? '-12px' : '-5px'}
			text={t('all:upgradeYourPlanToUse')}
			arrowPosition="left"
			whiteSpace="pre-wrap"
			disableTooltip={!isMenuItemDisabled}
		>
			<Link
				className={clsx(className, 'menuItem')}
				to={isMenuItemDisabled && location
					? location.pathname + location.search
					: routeWithSortString}
				onClick={isMenuItemDisabled ? undefined : onClick}
				disabled={isMenuItemDisabled}
			>
				<StyledMenuItem
					className={clsx(isExtended ? 'extendedMenu' : 'condensedMenu', isActive && 'active')}
					activeBGColor={activeBGColor}
				>
					<Icon
						name={iconName}
						width={20}
						height={20}
						className={clsx(iconName, 'menuItemIcon')}
						fill={iconFill}
					/>
					<Typography
						variant={isMobile ? 'button3' : 'button1'}
						text={t(text || '')}
						className={clsx(isExtended ? 'extendedItem' : 'condensedItem')}
					/>

					{isAlphaVersion && isExtended && (
						<Tag
							text="alpha"
							backgroundColor={theme.color.primary.main}
							className="alpha"
							color={theme.color.general.white}
						/>
					)}

					{(route || to) === '/chats' && (
						<Badge
							invisible={newChatNotificationsCount === 0 || isChatOperator}
							badgeContent={isExtended && newChatNotificationsCount}
							variant={isExtended ? 'general' : 'primary'}
							className="menuItemBadge"
							isMini={!isExtended}
						/>
					)}
				</StyledMenuItem>
			</Link>
		</Tooltip>
	);
};

export default MenuItem;