import { nanoid } from 'nanoid';
import {
	GET_CHATS_LIST,
	GET_CHATS_LIST_SUCCESS,
	GET_CHAT_DETAILS,
	GET_CHAT_DETAILS_ERROR,
	GET_CHAT_DETAILS_ON_SCROLL,
	GET_CHAT_DETAILS_ON_SCROLL_SUCCESS,
	GET_CHAT_DETAILS_SUCCESS,
	GET_ONLINE_CONTACTS_SUCCESS,
	HIDE_CONTACT_TYPING,
	RESET_FOUND_MESSAGES,
	RESET_NEW_CHAT_HISTORY_NOTIFICATIONS,
	SEARCH_CHAT_MESSAGE,
	SEARCH_CHAT_MESSAGE_SUCCESS,
	SET_CHAT_MESSAGE,
	SET_CONTACT_NAME_TO_CHAT,
	SET_CONTACT_ONLINE_STATUS,
	SET_DATA_TYPE,
	SET_EVENT_TO_CHAT_HISTORY,
	SET_EVENT_TO_CHAT_HISTORY_ERROR,
	SET_EVENT_TO_CHAT_HISTORY_SUCCESS,
	SET_FOUND_MESSAGES_IDS,
	SET_IS_EVENT_JUST_SENT,
	SET_NEW_CHAT_HISTORY_NOTIFICATION,
	SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL,
	SIGN_OUT,
	GET_CHATS_COUNT_NEW,
	DELETE_CHAT,
	REPLIED_MESSAGE,
	SET_CHAT_MESSAGE_ID_TO_LAST_MESSAGE,
} from '../constants';
import { ChatStatus, IAction, IChatsState } from '../../types';

const defaultState: IChatsState = {
	repliedMessage: null,
	list: {
		new: [],
		open: [],
		closed: [],
	},
	chatsCount: {
		new: 0,
		open: 0,
		closed: 0,
	},
	inProgressSetEventToHistory: false,
	contactsOnline: null,
	chatInfo: {
		// @ts-ignore
		status: '',
		// @ts-ignore
		contact_id: '',
		contact_uid: '',
		contact_name: '',
	},
	lastMessageData: null,
	chatHistory: [],
	chatHistoryCount: 0,
	inProgressHistory: false,
	inProgressHistoryOnScroll: false,
	inProgressSearchMessage: false,
	allMessagesLoadedUp: false,
	allMessagesLoadedDown: true,
	foundMessagesIds: [],
	error: null,
	newChatHistoryNotifications: undefined,
	chatMessage: '',
	eventJustSent: '',
	lastMessageId: null,
};

type TStatus = 'new' | 'open' | 'closed';

export default (state = defaultState, action = {} as IAction): IChatsState => {
	switch (action.type) {
		case SET_CHAT_MESSAGE:
			return {
				...state,
				chatMessage: action.payload.message || '',
			};
		case GET_CHATS_LIST:
			return { ...state, inProgressClosed: true };
		case REPLIED_MESSAGE:
			return { ...state, repliedMessage: action?.payload };
		case GET_CHATS_LIST_SUCCESS: {
			const status = action?.payload?.status as TStatus;
			const isInfiniteScroll = action?.payload?.isInfiniteScroll;

			return {
				...state,
				inProgressClosed: false,
				list: {
					...state.list,
					[status]: isInfiniteScroll
						? [...state.list[status], ...action.payload.items]
						: action.payload?.items,
				},
				chatsCount: {
					...state.chatsCount,
					[status]: action.payload.total,
				},
			};
		}
		case GET_ONLINE_CONTACTS_SUCCESS:
			return {
				...state,
				contactsOnline: action.payload?.length
					? action.payload.reduce((acc: Record<string, boolean>, curr: string) => {
						acc[curr] = true;
						return acc;
					}, {})
					: {},
			};
		case SET_CONTACT_ONLINE_STATUS:
			return {
				...state,
				contactsOnline: {
					...(state.contactsOnline || {}),
					[action.payload.contactId]: action.payload.isOnline,
				},
			};
		case GET_CHAT_DETAILS_ERROR:
		case SET_EVENT_TO_CHAT_HISTORY_ERROR:
			return {
				...state,
				inProgress: false,
				inProgressClosed: false,
				inProgressSetEventToHistory: false,
				error: true,
			};
		case SET_CONTACT_NAME_TO_CHAT: {
			const { contact_name, chat_id } = action.payload;

			const mapChats = (status: TStatus) => state.list[status].map((chat) => {
				if (chat.id === chat_id) {
					return { ...chat, contact_name };
				}
				return chat;
			});

			return {
				...state,
				chatInfo:
					state.chatInfo.id === chat_id
						? { ...state.chatInfo, contact_name }
						: state.chatInfo,
				list: {
					new: mapChats('new'),
					open: mapChats('open'),
					closed: mapChats('closed'),
				},
			};
		}
		case GET_CHAT_DETAILS:
			return {
				...state,
				inProgressHistory: true,
				allMessagesLoadedUp: false,
			};
		case GET_CHAT_DETAILS_SUCCESS:
			return {
				...state,
				inProgressHistory: false,
				chatHistory: action.payload.history,
				chatInfo: action.payload.chatData || state.chatInfo,
				chatHistoryCount: action.payload.total,
				allMessagesLoadedUp: action.payload.allMessagesLoadedUp,
				allMessagesLoadedDown: action.payload.allMessagesLoadedDown,
				limit: action.payload.limit,
				isHistoryFirstLoadWithPictures: action.payload.isHistoryFirstLoadWithPictures,
				lastMessageId: action.payload.lastMessageId || state.lastMessageId,
			};
		case GET_CHAT_DETAILS_ON_SCROLL:
			return {
				...state,
				inProgressHistoryOnScroll: true,
			};
		case GET_CHAT_DETAILS_ON_SCROLL_SUCCESS: {
			const { isScrollUp, history } = action.payload;

			const newHistory = [
				...(isScrollUp ? history : []),
				...state.chatHistory,
				...(!isScrollUp ? history : []),
			];
			const allItemsLoaded = state.chatHistoryCount === newHistory.length;

			return {
				...state,
				inProgressHistoryOnScroll: false,
				chatHistory: newHistory,
				[isScrollUp ? 'allMessagesLoadedUp' : 'allMessagesLoadedDown']:
          action.payload.allMessagesLoadedInScrollDirection,
				...(allItemsLoaded ? { allMessagesLoadedUp: true, allMessagesLoadedDown: true } : {}),
			};
		}
		case SEARCH_CHAT_MESSAGE:
			return {
				...state,
				allMessagesLoadedUp: false,
				allMessagesLoadedDown: false,
				inProgressSearchMessage: true,
			};
		case SEARCH_CHAT_MESSAGE_SUCCESS: {
			const allItemsLoaded = state.chatHistoryCount === action.payload.history.length;

			const newValues = {
				allMessagesLoadedUp: allItemsLoaded || action.payload.allMessagesLoadedUp,
				allMessagesLoadedDown: allItemsLoaded || action.payload.allMessagesLoadedDown,
				chatHistory: action.payload.history,
			};
			return {
				...state,
				inProgressSearchMessage: false,
				...(action.payload.history?.length ? newValues : {}),
			};
		}
		case SET_FOUND_MESSAGES_IDS:
			return {
				...state,
				foundMessagesIds: action.payload.reverse(),
			};
		case RESET_FOUND_MESSAGES:
			return {
				...state,
				foundMessagesIds: [],
			};
		case SET_EVENT_TO_CHAT_HISTORY:
			return {
				...state,
				inProgressSetEventToHistory: true,
			};
		case SET_IS_EVENT_JUST_SENT:
			return {
				...state,
				eventJustSent: action.payload.eventJustSent,
			};
		case HIDE_CONTACT_TYPING: {
			return {
				...state,
				chatHistory: state.chatHistory.filter((item) => item.type !== 'contact_typing'),
			};
		}

		case DELETE_CHAT: {
			const { chatId } = action.payload;

			const stateClone = JSON.parse(JSON.stringify(state));

			const updated: IChatsState = {
				...state,
				list: {
					new: state.list.new.filter((i) => i.id !== chatId),
					open: state.list.open.filter((i) => i.id !== chatId),
					closed: state.list.closed.filter((i) => i.id !== chatId),
				},
			};

			const deltaCountNew = updated.list.new.length - stateClone.list.new.length;
			const deltaCountOpen = updated.list.open.length - stateClone.list.open.length;
			const deltaCountClosed = updated.list.closed.length - stateClone.list.closed.length;

			updated.chatsCount.new += deltaCountNew;
			updated.chatsCount.open += deltaCountOpen;
			updated.chatsCount.closed += deltaCountClosed;

			return updated;
		}

		case SET_EVENT_TO_CHAT_HISTORY_SUCCESS: {
			const {
				chatsToDelete = [], chatToSet = {}, event, eventType, status,
			} = action.payload;

			const stateClone = JSON.parse(JSON.stringify(state));

			const { data: chatDataToSet, id: chatIdToSet } = chatToSet;

			const newEventTempId = event?.id || state.lastMessageData?.messageId || nanoid();

			if (event.data && state.lastMessageData?.channelMessageId) {
				event.data.channel_message_id = state.lastMessageData?.channelMessageId;
			}

			const updatedChatHistory = state.chatHistory[state.chatHistory.length - 1]
				?.type === 'contact_typing'
				? [
					...state.chatHistory.slice(0, state.chatHistory.length - 1),
					{ id: newEventTempId, ...event },
					state.chatHistory[state.chatHistory.length - 1],
				]
				: [...state.chatHistory, { id: newEventTempId, ...event }];

			const newStateData: IChatsState = {
				...state,
				inProgress: false,
				inProgressSetEventToHistory: false,
				chatHistory: event ? updatedChatHistory : state.chatHistory,
				chatHistoryCount: state.chatHistoryCount + 1,
			};

			if (event && eventType !== 'contact_typing') {
				newStateData.lastMessageId = newEventTempId;
			}

			if (state.chatInfo?.id === chatDataToSet?.id) {
				newStateData.chatInfo = chatDataToSet;
			}

			chatsToDelete.forEach(({ id: toDeleteId, status: toDeleteStatus }: {
				id: string;
				status: string;
			}) => {
				newStateData.list[toDeleteStatus as ChatStatus] = state.list[toDeleteStatus as ChatStatus]
					.filter(({ id }: { id: number }) => id !== +toDeleteId);

				if (state.chatInfo?.id === +toDeleteId && !chatDataToSet) {
					// @ts-ignore
					newStateData.chatInfo = {};
				}
			});

			if (chatDataToSet) {
				if (chatIdToSet) {
					const currentChatId = state.chatInfo?.id;

					const needFilterMessage = currentChatId !== chatDataToSet.id
						&& (eventType === 'contact_message' || eventType === 'callback_message')
						&& chatDataToSet.status !== 'closed';

					if (needFilterMessage) {
						newStateData.list[status as ChatStatus] = [
							chatDataToSet,
							...state.list[status as ChatStatus].filter(({ id }) => id !== +chatIdToSet),
						];
					} else {
						newStateData.list[status as ChatStatus] = state.list[status as ChatStatus]
							.map((chat) => (chat.id === +chatIdToSet ? chatDataToSet : chat));
					}
				} else {
					newStateData.list[status as ChatStatus] = chatsToDelete[0]?.status === status
						? [chatDataToSet, ...newStateData.list[status as ChatStatus]]
						: [chatDataToSet, ...state.list[status as ChatStatus]];
				}
			}

			const deltaCountNew = newStateData.list.new.length - stateClone.list.new.length;
			const deltaCountOpen = newStateData.list.open.length - stateClone.list.open.length;
			const deltaCountClosed = newStateData.list.closed.length - stateClone.list.closed.length;

			newStateData.chatsCount.new += deltaCountNew;
			newStateData.chatsCount.open += deltaCountOpen;
			newStateData.chatsCount.closed += deltaCountClosed;

			newStateData.lastMessageData = null;

			return newStateData;
		}
		case SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL: {
			// filter out the closed chats
			const filteredNotifications = action.payload.withClosedChatsFilter
				? Object.entries(action.payload.dataFromLocalStorage)
					.reduce((acc, [chatId, messageIds]) => {
						if (
							state.list.new.some((chat) => chat.id === +chatId)
              || state.list.open.some((chat) => chat.id === +chatId)
						) {
							return { ...acc, [chatId]: messageIds };
						}
						return acc;
					}, {})
				: action.payload.dataFromLocalStorage;

			return {
				...state,
				newChatHistoryNotifications: filteredNotifications,
			};
		}
		case SET_NEW_CHAT_HISTORY_NOTIFICATION: {
			const { event } = action.payload;

			return {
				...state,
				newChatHistoryNotifications: {
					...state.newChatHistoryNotifications,
					[event?.chat_id]:
						[...(state.newChatHistoryNotifications?.[event?.chat_id] || []), event.id],
				},
			};
		}
		case RESET_NEW_CHAT_HISTORY_NOTIFICATIONS: {
			const { chatId } = action.payload;

			return {
				...state,
				newChatHistoryNotifications: { ...state.newChatHistoryNotifications, [chatId]: undefined },
			};
		}
		case SET_DATA_TYPE:
			return {
				...state,
				chatInfo: defaultState.chatInfo,
			};
		case GET_CHATS_COUNT_NEW: {
			return {
				...state,
				chatsCount: {
					...state.chatsCount,
					new: action.payload || 0,
				},
			};
		}
		case SET_CHAT_MESSAGE_ID_TO_LAST_MESSAGE: {
			return {
				...state,
				lastMessageData: action.payload,
			};
		}
		case SIGN_OUT:
			return defaultState;
		default:
			return state;
	}
};