import { ChatStatus, IDataState, IStore } from '../types';

export const selectWholeUserState = (state: IStore) => state.user;

export const selectUserDetails = (state: IStore) => state.user.details;

export const selectUserSubscriptionDetails = (state: IStore) => state.user.subscriptionDetails;

export const selectIsFreePlan = (state: IStore) => state.user.subscriptionDetails?.plan_id === 1;

export const selectUserName = (state: IStore) => state.user.details.member_full_name;

export const selectUserMemberId = (state: IStore) => state.user.details.member_id;

export const selectUserCompanyId = (state: IStore) => state.user.details.customer_id;

export const selectUserNameWithPic = (state: IStore) => ({
	name: state.user.details.member_full_name,
	pic: state.user.details.member_profile_picture,
});

export const selectUserRole = (state: IStore) => state.user.details.role;

export const selectUserToken = (state: IStore) => state.user.token;

export const selectUserInviteDetails = (state: IStore) => state.user.inviteDetails;

export const selectUserSystemParams = (state: IStore) => ({
	isAuthenticated: state.user.isAuthenticated,
	isAppInitialized: state.user.isAppInitialized,
	inProgress: state.user.inProgress,
});

export const selectIsUserOnline = (state: IStore) => state.user.isUserOnline;

export const selectResetPassMetaData = (state: IStore) => ({
	inProgress: state.user.resetPassInProgress,
	error: state.user.error,
});

export const selectCurrentSubscription = (state: IStore) => state.user.subscriptionDetails;

// company related

export const selectCompanyData = (state: IStore) => state.company.general;

export const selectCompanyId = (state: IStore) => state.company.general?.id;

export const selectCompanyMetaData = (state: IStore) => ({
	inProgress: state.company.inProgress,
	error: state.company.error,
});

export const selectBillingPlans = (state: IStore) => state.company.billingPlans;

// unsorted

export const selectTableTotalItems = (state: IStore) => state.data.total_items;

export const selectMainDataLoading = (state: IStore) => state.data.inProgress;

export const selectMainDataInitialized = (state: IStore) => state.data.isDataInitialized;

export const selectMainData = (type: keyof IDataState) => (state: IStore) => state.data[type];

export const selectDataType = (state: IStore) => state.data.type;
export const selectSidebarDetails = (state: IStore) => state.sidebar.details;

export const selectSidebarDetailsStatus = (state: IStore) => state.sidebar.details?.status;

export const selectSidebarMetaData = (state: IStore) => ({
	inProgress: state.sidebar.sidebarInProgress,
	error: state.sidebar.error,
});

export const selectPopupAlerts = (state: IStore) => state.common.popupAlerts;

export const selectIsNavMenuExtended = (state: IStore) => state.common.isNavMenuExtended;

export const selectIsDrawerExtended = (state: IStore) => state.common.isDrawerExtended;

export const selectAllChatsLists = (state: IStore) => ({
	new: state.chats.list.new,
	open: state.chats.list.open,
	closed: state.chats.list.closed,
});

export const selectChatRepliedMessage = (state: IStore) => state.chats.repliedMessage;

export const selectChatsCounts = (state: IStore) => state.chats.chatsCount;

export const selectChatsList = (status: ChatStatus) => (state: IStore) => state.chats.list[status];

export const selectCurrentChatInfo = (state: IStore) => state.chats.chatInfo;

export const selectCurrentChatChannel = (state: IStore) => state.chats.chatInfo?.channel;

export const selectCurrentChatContactId = (state: IStore) => state.chats.chatInfo?.contact_id;

export const selectCurrentChatStatus = (state: IStore) => state.chats?.chatInfo?.status;

export const selectCurrentChatId = (state: IStore) => state.chats?.chatInfo?.id;

export const selectCurrentChatHistory = (state: IStore) => ({
	chatHistory: state.chats.chatHistory,
	chatHistoryCount: state.chats.chatHistoryCount,
	inProgressHistory: state.chats.inProgressHistory,
	allMessagesLoadedUp: state.chats.allMessagesLoadedUp,
	allMessagesLoadedDown: state.chats.allMessagesLoadedDown,
	inProgressSearchMessage: state.chats.inProgressSearchMessage,
	inProgressHistoryOnScroll: state.chats.inProgressHistoryOnScroll,
	limit: state.chats.limit,
	lastMessageId: state.chats.lastMessageId,
	isHistoryFirstLoadWithPictures: state.chats.isHistoryFirstLoadWithPictures,
});

export const selectCurrentChatHistoryCount = (state: IStore) => state.chats.chatHistoryCount;

export const selectIsContactOnline = (id?: number) => (state: IStore) => {
	const contactId = id || state.chats.chatInfo?.contact_id;
	return contactId && state.chats.contactsOnline?.[contactId];
};

export const selectChatsClosedInProgress = (state: IStore) => state.chats.inProgressClosed;

export const selectChatsNotClosedInProgress = (state: IStore) => state.chats.inProgressNotClosed;

export const selectFoundMessagesIds = (state: IStore) => state.chats.foundMessagesIds;

export const selectLastMessageIdCurrent = (state: IStore) => {
	const { chatHistory } = state.chats;
	const contactTypingIndex = chatHistory.findIndex((event) => event.type === 'contact_typing');

	return contactTypingIndex === -1
		? chatHistory[chatHistory.length - 1]?.id
		: chatHistory[contactTypingIndex - 1]?.id;
};

export const selectLastHistoryMessageId = (state: IStore) => state.chats.lastMessageId;

export const selectFirstMessageId = (state: IStore) => state.chats.chatHistory[0]?.id;

export const selectIsNoChatsFound = (state: IStore) => {
	const { new: newChats, open: openChats, closed: closedChats } = state.chats.list;
	return !newChats.length && !openChats.length && !closedChats.length;
};

export const selectChatMessage = (state: IStore) => state.chats.chatMessage;

export const selectSetEventToHistoryInProgress = (state: IStore) => (
	state.chats.inProgressSetEventToHistory
);

export const selectEventJustSent = (state: IStore) => state.chats.eventJustSent;

export const selectFilesUpload = (state: IStore) => state.fileUpload;

export const selectFilesDraft = (state: IStore) => state.fileUpload.filesChosen;

export const selectFilesUploaded = (state: IStore) => state.fileUpload.filesUploaded;

export const selectNewChatNotifications = (state: IStore) => (
	state.chats.newChatHistoryNotifications
);

export const selectFileUrlsLoaded = (state: IStore) => state.fileUpload.fileUrlsLoadedByCode;

export const selectFormValues = (state: IStore) => state.forms.formValues;