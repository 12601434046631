import uk from 'date-fns/locale/uk';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Locale } from 'date-fns';
import { transformDate } from '../../../utils/dates';
import FlexRow from '../../atoms/FlexRow/FlexRow';
import TimeInputWithMask from '../../molecules/TimeInputWithMask/TimeInputWithMask';
import DatePicker from '../DatePicker/DatePicker';

registerLocale('uk', uk as unknown as Locale);

const DateTimeInputs = ({
	value, onChange, disabled, name,
}: {
	value: Date;
	onChange: (name: string) => (value: Date) => void;
	disabled?: boolean;
	name: string;
}) => {
	const {
		i18n: { language: currentLng },
		t,
	} = useTranslation('all');

	const [startDate, setStartDate] = useState(value);
	const [startTime, setStartTime] = useState(transformDate(value, 'HH:mm'));
	useEffect(() => {
		setStartDate(new Date(value));
		setStartTime(transformDate(new Date(value), 'HH:mm'));
	}, [value]);

	const onDateChange = (value: string | number) => {
		// @ts-ignore
		const newDate = new Date(dayjs(value));
		setStartDate(newDate);
		if (onChange) onChange(name)(newDate);
	};

	const onBlur = () => {
		const hour = Number(startTime.split(':')[0]);
		const minute = Number(startTime.split(':')[1]);

		const newDate = new Date(
			// @ts-ignore
			dayjs(startDate).set('hour', hour || '00').set('minute', minute || '00').set('second', '00'),
		);

		setStartDate(newDate);
		if (onChange) onChange(name)(newDate);
	};

	return (
		<FlexRow flexDirection="column" gap="20px" alignItems="stretch" margin="20px 0 0">
			<DatePicker
				disabled={disabled}
				selectsRange={false}
				// @ts-ignore
				onChange={onDateChange}
				currLang={currentLng}
				t={t}
				withIcon
				value={startDate}
				minDate={new Date()}
				label={t('date')}
				labelType="border"
			/>
			<TimeInputWithMask
				disabled={disabled}
				value={startTime}
				label={t('time')}
				mask="Hm:Mm"
				formatChars={{
					// @ts-ignore
					H: '[0-2]',
					// @ts-ignore
					h: '[0-9]',
					// @ts-ignore
					M: '[0-5]',
					// @ts-ignore
					m: '[0-9]',
				}}
				onChange={(e) => setStartTime(e.target.value)}
				onBlur={onBlur}
			/>
		</FlexRow>
	);
};

export default DateTimeInputs;