import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Accept, useDropzone } from 'react-dropzone';
import { useTheme } from 'styled-components';

import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { StyledDropButton, StyledDropZone } from './styled';
import { popupAlertShow } from '../../redux-saga/actions';

interface IProps {
	className?: string;
	currentFiles?: Blob[];
	fileType: Accept;
	isSimpleButton: boolean;
	maxFiles: number;
	maxSize: number;
	setFiles: (files: Blob[]) => void;
	type: string;
	noClick?: boolean;
	multiple: boolean;
	setIsDropZoneActive?: (isActive: boolean) => void;
}

const DropZone = (props: IProps) => {
	const {
		className,
		currentFiles,
		fileType,
		isSimpleButton,
		maxFiles,
		maxSize,
		setFiles,
		type,
		noClick,
		multiple,
		setIsDropZoneActive,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('all');
	const dispatch = useDispatch();

	const getNewFiles = (newFiles: { file: Blob }[]) => {
		setFiles(
			newFiles.map((file) => Object.assign(file.file, {
				preview: URL.createObjectURL(file.file),
			})),
		);

		dispatch(
			popupAlertShow({
				contentKey: 'only10Files',
				type: 'error',
				timeout: 10000,
				withCloseButton: true,
				iconName: 'statusDeleted',
			}),
		);
	};

	const {
		getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles,
	} = useDropzone({
		accept: fileType,
		multiple,
		noClick,
		onDropRejected(fileRejections) {
			if (!acceptedFiles.length && fileRejections.length > maxFiles) {
				const newFiles = fileRejections.slice(0, maxFiles);
				getNewFiles(newFiles);
			}

			const maxNewFiles = maxFiles - acceptedFiles.length;
			if (acceptedFiles.length && fileRejections.length > maxNewFiles) {
				const newFiles = fileRejections.slice(0, maxNewFiles);
				getNewFiles(newFiles);
			}

			fileRejections.forEach((file) => {
				if (file.errors[0].code === 'file-too-large') {
					dispatch(
						popupAlertShow({
							contentKey: 'file-too-large',
							contentParams: { fileName: file.file.name },
							type: 'error',
							timeout: 10000,
							withCloseButton: true,
							iconName: 'statusDeleted',
						}),
					);
				}
			});
		},
		onDrop(acceptedFiles) {
			if (!acceptedFiles?.length) {
				return;
			}

			const maxNewFiles = maxFiles - (currentFiles?.length || 0);
			if (multiple && acceptedFiles.length > maxNewFiles) {
				acceptedFiles.splice(maxNewFiles);

				dispatch(
					popupAlertShow({
						contentKey: 'only10Files',
						type: 'error',
						timeout: 10000,
						withCloseButton: true,
						iconName: 'statusDeleted',
					}),
				);
			}

			// @ts-ignore
			const newFiles: Blob[] = currentFiles
				// @ts-ignore
				? acceptedFiles.reduce((prev, curr) => {
					// @ts-ignore
					if (currentFiles.findIndex((currFile) => currFile.name === curr.name) === -1) {
						return [...prev, curr];
					}
					return prev;
				}, [])
				: acceptedFiles;

			setFiles(
				newFiles.map((file) => Object.assign(file, {
					preview: URL.createObjectURL(file),
				})),
			);
		},
		maxFiles,
		maxSize,
	});

	useEffect(() => {
		if (setIsDropZoneActive) {
			setIsDropZoneActive(isDragActive);
		}
	}, [isDragActive]);

	if (isSimpleButton) {
		return (
			<StyledDropButton
				{...getRootProps({ isDragActive, isDragAccept, isDragReject })}
				className={className}
			>
				<input {...getInputProps()} id={type} />
			</StyledDropButton>
		);
	}

	return (
		<StyledDropZone
			{...getRootProps({ isDragActive, isDragAccept, isDragReject })}
			className={className}
		>
			<input {...getInputProps()} id={type} />
			<Icon name="upload" />
			<Typography variant="input" textAlign="center" color={theme.color.general.light}>
				{t('uploadAndImage')}
			</Typography>
		</StyledDropZone>
	);
};

export default DropZone;

DropZone.defaultTypes = { noClick: false };