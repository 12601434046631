import React, {
	Suspense,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Link from '../../atomic-design-components/atoms/Link/Link';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import HeaderMobile from '../../components/HeaderMobile/HeaderMobile';
import NavMenu from '../../components/NavMenu/NavMenu';
import {
	ENTITIES, MY_COMPANY, PAGES_WITHOUT_NAV_LINK, SETTINGS,
} from '../../config';
import { NO_ACTIVE_CHANNELS_WARNING_ID } from '../../constants';
import { ScreenContext } from '../../hooks/useScreenContext';
import {
	getTableData,
	popupAlertHide,
	popupAlertHideAll,
	popupAlertShow, setChatsCountNew,
	setDataType,
} from '../../redux-saga/actions';
import { selectMainData, selectPopupAlerts, selectUserRole } from '../../redux-saga/selectors';
import theme from '../../theme';
import ErrorBoundary from '../../wrappers/ErrorBoundary';
import { StyledMainWrapper } from './styled';
import { IDummyData } from '../../types';
import { buildRequestFromComponent, fetchData } from '../../api';

const getEntityByRoute = (pathname: string) => {
	const getEntity = (configObject: IDummyData) => {
		const array = Array.isArray(configObject) ? configObject : Object.values(configObject).flat();
		return array.find((entity) => entity.route !== '/' && pathname.includes(entity.route));
	};

	return pathname === '/'
		? { key: 'dashboard' }
		: getEntity(ENTITIES)
		|| getEntity(MY_COMPANY)
		|| getEntity(SETTINGS)
		|| getEntity(PAGES_WITHOUT_NAV_LINK);
};

const getHasActiveChannel = (widgets: {
	status: string;
	channels: {
		[key: string]: {
			status: string;
		};
	};
}[]) => {
	if (widgets.length > 0) {
		return widgets.some((widget) => {
			if (widget.status === 'active') {
				return Object.values(widget.channels).some((channel) => channel.status === 'active');
			} return widget;
		});
	}
	return false;
};

const MainWrapper = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation('popupAlerts');

	const {
		width, md, xl, height,
	} = useContext(ScreenContext);
	const isTablet = width <= xl && width > md;
	const isRevertView = width > height;

	const { pathname } = useLocation();
	const typeData = getEntityByRoute(pathname);

	const [isWarningShown, setIsWarningShown] = useState(false);
	const widgets = useSelector(selectMainData('widgets'));

	const currentUserRole = useSelector(selectUserRole);
	const popupAlerts = useSelector(selectPopupAlerts);

	const linkToWidgets = () => (
		<>
			<Typography text={t('noActiveChannelsText')} variant="body2" className="popupText" />
			<Link to="/my-company/chat-widgets" noStyles themeColor="general.white">
				<Typography
					style={{
						margin: '0 10px',
					}}
					text={t('noActiveChannelsLink')}
					variant="caption1"
					className="popupLinkText"
					color={theme.color.status.error}
				/>
			</Link>
		</>
	);

	useEffect(() => {
		if (typeData?.key !== 'widgets') {
			dispatch(
				getTableData({
					type: 'widgets',
					isSystemData: true,
				}),
			);
		}
	}, [typeData?.key]);

	const getChatsCountFromServer = async () => {
		try {
			const request = buildRequestFromComponent({
				apiMethod: 'GET',
				type: 'chatCounter',
			});

			const response: number = await fetchData(request);

			dispatch(setChatsCountNew(response));
		} catch (e) {
			// empty error
		}
	};

	useEffect(() => {
		getChatsCountFromServer();
	}, []);

	useEffect(() => {
		const isShown = widgets && !getHasActiveChannel(widgets);
		setIsWarningShown(isShown);
	}, [widgets]);

	useEffect(() => {
		if (isWarningShown) {
			const hasPopup = popupAlerts.find((popup) => popup.id === NO_ACTIVE_CHANNELS_WARNING_ID);
			if (!hasPopup) {
				dispatch(
					popupAlertShow({
						id: NO_ACTIVE_CHANNELS_WARNING_ID,
						content: linkToWidgets(),
						type: 'warning',
						timeout: 0,
						iconName: 'warningSign',
						withCloseButton: true,
						className: 'oneLinePopup',
					}),
				);
			}
		} else {
			dispatch(popupAlertHide(NO_ACTIVE_CHANNELS_WARNING_ID));
		}
	}, [isWarningShown, pathname]);

	useEffect(() => {
		if (typeData) {
			dispatch(popupAlertHideAll());
			dispatch(setDataType(typeData.key));
		}
	}, [JSON.stringify(typeData)]);

	if (width <= xl) {
		return (
			<StyledMainWrapper>
				<HeaderMobile colorScheme={currentUserRole} isTablet={isTablet} isRevert={isRevertView} />

				<Suspense fallback={null}>
					<Outlet />
				</Suspense>
			</StyledMainWrapper>
		);
	}

	return (
		<StyledMainWrapper>
			<ErrorBoundary>
				<NavMenu colorScheme={currentUserRole} />
			</ErrorBoundary>

			<Suspense fallback={null}>
				<Outlet />
			</Suspense>
		</StyledMainWrapper>
	);
};

export default MainWrapper;